<template>
  <v-card>
    <v-form @submit.prevent="submit" ref="form">
      <v-card-title>
        <span class="headline">Nuevo pago</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-menu
                v-model="installmentDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="installmentDate"
                    label="Seleccione fecha del pago"
                    prepend-icon="fa-calendar-check"
                    readonly
                    v-on="on"
                    required :rules="rules.empty"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="installmentDate" @input="installmentDatePicker = false" />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="saveForm">Guardar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import range from 'lodash/range'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ContributionInstallmentForm',
  components: {},
  created () {},
  props: {},
  data () {
    return {
      loading: true,
      numLinesToCreate: 1,
      editingErrors: {},
      rules: {
        empty: [val => this.isEmpty(val)]
      },
      installmentDate: null,
      installmentDatePicker: false
    }
  },
  computed: {
    ...mapState('contributions', {
      currentContribution: 'currentItem'
    }),
    itemListToSend () {
      return range(this.numLinesToCreate).map(idx => ({
        date: this.installmentDate,
        contribution: this.currentContribution.external_id,
        created_from: 1
      })
      )
    }
  },
  methods: {
    ...mapActions('contributioninstallments', ['postManyItems', 'getValidPortfolios', 'getItemList']),

    isEmpty (value) {
      if (!value && value !== 0) {
        return 'Este campo es requerido'
      } else {
        return true
      }
    },

    closeForm () {
      this.$emit('close')
    },
    saveForm () {
      if (this.$refs.form.validate()) {
        this.postManyItems(this.itemListToSend).then(
          () => this.$emit('save'),
          this.$refs.form.reset(),
          this.closeForm()
        )
      }
    }
  },

  updated () {
    this.$refs.form.resetValidation()
  }
}
</script>

<style scoped>

</style>
