<template>
  <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems" :customOptions="customOptions"
             @save="onRappelEdition" :customActions="customActions" :parentKey="this.parentKey" :parentValue="this.parentValue"
             :editableValues="editableValues">

    <template v-slot:form="{close}">
      <RappelForm @save="afterCreation()" @close="close"/>
    </template>

  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState, mapActions } from 'vuex'
import RappelForm from '../forms/RappelForm'
import Lget from 'lodash/get'
import Lset from 'lodash/set'

export default {
  name: 'RappelTable',
  components: { RappelForm, CrudTable },

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'rappels',
      filterItems: [],
      customOptions: {
        'sortBy': ['level'],
        'sortDesc': [false]
      },
      headers: [
        { text: 'Nivel', value: 'level', formatNumber: false, align: 'end', hide: false, editable: true, selector: true, modelToUse: 'distributors/validLevelsList' },
        { text: 'Escalón', value: 'step', formatNumber: true, type: 'integer', align: 'end', hide: false, editable: true },
        { text: 'Beneficiario', value: 'beneficiary', formatNumber: false, align: 'end', hide: false, editable: true, autocomplete: true, modelToUse: 'distributors', itemText: 'name' },
        { text: 'Período de liquidación', value: 'settlement_type', formatNumber: false, align: 'end', hide: false, editable: true, selector: true, modelToUse: 'rappels/settlementTypesList' },
        { text: '% extra cajas sobre cajas propuestas', type: 'float', value: 'extra_boxes_over_proposed', formatNumber: true, align: 'end', hide: false, editable: true },
        { text: '% rappel adicional', type: 'float', value: 'extra_rappel_to_apply', formatNumber: true, align: 'end', hide: false, editable: true },
        { text: '€/caja de rappel adicional', type: 'float', value: 'eur_box_additional_rappel', formatNumber: true, align: 'end', hide: false, editable: true }
      ],
      customActions: [
        {
          name: 'duplicate',
          text: 'duplicar seleccionado(s)',
          icon: 'fa-copy',
          action: this.duplicateSelected
        }
      ]
    }
  },

  computed: {
    ...mapState('agreements', { currentAgreement: 'currentItem' }),
    ...mapState('distributors', ['validLevels'])
  },

  created () {
    this.getSettlementTypes()
    this.getValidLevels()
  },
  methods: {
    Lget: Lget,
    ...mapActions('rappels', {
      getRappels: 'getItemList',
      getSettlementTypes: 'getSettlementTypes',
      reloadItemList: 'reloadItemList',
      postItem: 'postItem'
    }),
    ...mapActions('distributors', ['getValidLevels']),

    afterCreation () {
      this.reloadItemList()
    },

    onRappelEdition (data) {
      let updatedItem = Lset(data.item, data.header, data.value)

      const discountPath = 'extra_rappel_to_apply'
      const eurBoxPath = 'eur_box_additional_rappel'

      // this if - elseif performs the logic to only allow one type of discount (eut_box vs anticipated)
      if (data.header === discountPath) {
        updatedItem = Lset(data.item, eurBoxPath, 0)
      } else if (data.header === eurBoxPath) {
        updatedItem = Lset(data.item, discountPath, 0)
      }

      let beneficiary = Lget(updatedItem, 'beneficiary', '')
      const hasExternalId = beneficiary && beneficiary instanceof Object && Object.keys(beneficiary).includes('external_id')
      beneficiary = hasExternalId ? beneficiary.external_id : ''

      this.postItem(
        {
          external_id: updatedItem.external_id,
          level: Lget(updatedItem, 'level', ''),
          step: Lget(updatedItem, 'step', 0),
          beneficiary: beneficiary,
          settlement_type: Lget(updatedItem, 'settlement_type', 'yearly'),
          agreement: this.currentAgreement.external_id,
          extra_rappel_to_apply: Lget(updatedItem, 'extra_rappel_to_apply', 0),
          extra_boxes_over_proposed: Lget(updatedItem, 'extra_boxes_over_proposed', 0),
          eur_box_additional_rappel: Lget(updatedItem, 'eur_box_additional_rappel', 0)
        }
      )
        .then(
          () => {
            this.reloadItemList()
          }
        )
    },

    duplicateSelected (data) {
      // reset external_id because if not update item and dont duplicate it
      let itemsToPost = data.items
        .map(item => ({
          ...item,
          agreement: item.agreement.external_id,
          beneficiary: item.creditor ? item.beneficiary.external_id : undefined,
          id: undefined,
          external_id: undefined
        }))
      confirm('¿Seguro que quieres duplicar los elementos seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/postManyItems`, itemsToPost).catch((error) => {
            console.log(error)
          }).then(
            () => data.reloadItemList()
          )
    }
  }
}
</script>

<style scoped>

</style>
