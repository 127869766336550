<template>
  <div>
    <v-alert v-if="incorrectValues" type="error">
      {{ errorMessage }}
    </v-alert>
    <v-alert v-if="validPayments" type="success">
      {{ successMessage }}
    </v-alert>
    <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
               @save="onContributionInstallmentEdition" :customActions="customActions" :parentKey="this.parentKey"
               :parentValue="this.parentValue" :customOptions="customOptions"
               :editableValues="editableValues" v-on:item-deleted="onItemDeleted">

      <template v-slot:form="{close}">
        <ContributionInstallmentForm @save="afterCreation()" @close="close"/>
      </template>
    </CrudTable>
  </div>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions, mapState } from 'vuex'
import { isNull, isUndefined } from 'lodash'
import Lget from 'lodash/get'
import Lset from 'lodash/set'
import ContributionInstallmentForm from '../forms/ContributionInstallmentForm'

export default {
  name: 'ContributionInstallmentTable',
  components: { CrudTable, ContributionInstallmentForm },

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'contributioninstallments',
      filterItems: [],
      customOptions: {
        'sortBy': ['date'],
        'sortDesc': [false]
      },
      headers: [
        {
          text: 'Fecha de pago',
          value: 'date',
          align: 'end',
          datePicker: true,
          hide: false,
          formatNumber: false
        },
        {
          text: 'Importe',
          value: 'amount',
          align: 'end',
          editable: true,
          type: 'float',
          hide: false,
          formatNumber: true,
          total: 'total_amount'
        },
        {
          text: '%',
          value: 'percentage',
          align: 'end',
          editable: false,
          type: 'float',
          hide: false,
          formatNumber: true,
          isPercentage: true,
          total: 'total_percentage'
        },
        {
          text: 'Descripción',
          value: 'description',
          align: 'end',
          editable: true,
          hide: false,
          formatNumber: false,
          type: 'string'
        }
      ],
      customActions: [],
      contributionAmount: 0,
      totalAmount: 0,
      incorrectValues: false,
      validPayments: false,
      errorMessage: '',
      successMessage: ''
    }
  },
  watch: {
    parentValue: function (newVal, oldVal) {
      this.initialize()
    },
    totalAmount: function (newVal, oldVal) {
      let totalPayments = Math.floor(this.totalAmount)
      let contributions = Math.floor(this.contributionAmount)

      if (totalPayments === 0) {
        this.errorMessage = 'No se han realizado pagos'
        this.validPayments = false
        this.incorrectValues = true
      }

      if (totalPayments > contributions) {
        this.errorMessage =
          'La suma de los pagos: ' + totalPayments +
          '€ no coincide con la suma total de la aportación: ' + contributions + '€'
        this.validPayments = false
        this.incorrectValues = true
      }

      if (totalPayments < contributions && totalPayments !== 0) {
        let value = contributions - totalPayments
        this.errorMessage = 'Falta añadir ' + value + '€'
        this.validPayments = false
        this.incorrectValues = true
      }

      if (totalPayments === contributions) {
        this.successMessage = 'Se han agregado todos los pagos: ' + totalPayments + '€'
        this.validPayments = true
        this.incorrectValues = false
      }
    }
  },
  computed: {
    ...mapState('contributioninstallments', ['extra_fields', 'items']),
    ...mapState('contributions', { currentContribution: 'currentItem' })
  },

  created () {
    this.initialize()
  },
  methods: {
    ...mapActions('contributions', { getContribution: 'getItem' }),
    ...mapActions('contributioninstallments', [ 'getItemList', 'clearItemList' ]),
    Lget: Lget,

    recalculateTotalAmount () {
      this.totalAmount = 0
      this.items.forEach(item => {
        this.totalAmount += item.amount
      })
      if (this.totalAmount === 0) {
        this.errorMessage = 'No se han realizado pagos'
        this.validPayments = false
        this.incorrectValues = true
      }
    },

    initialize () {
      this.clearItemList()
      this.getContribution(this.parentValue)
        .then(
          () => {
            this.contributionAmount = this.currentContribution.amount !== 0 && !isNull(this.currentContribution.amount) &&
            !isUndefined(this.currentContribution.amount) ? this.currentContribution.amount : 0
            if (this.contributionAmount !== 0) {
              this.getInstallments()
            } else {
              this.errorMessage = 'El total de la aportación es 0'
              this.validPayments = false
              this.incorrectValues = true
            }
          }
        )
    },

    getInstallments () {
      this.getItemList({ contribution__external_id: this.currentContribution.external_id })
        .then(
          () => {
            this.recalculateTotalAmount()
          }
        )
    },

    afterCreation () {
      this.getInstallments()
    },

    onItemDeleted () {
      this.getInstallments()
    },

    onContributionInstallmentEdition (data) {
      let updatedItem = Lset(data.item, data.header, data.value)

      const amount = 'amount'
      const percentage = 'percentage'

      if (data.header === amount) {
        updatedItem = Lset(data.item, percentage, undefined)
      } else if (data.header === percentage) {
        updatedItem = Lset(data.item, amount, undefined)
      }

      this.$store
        .dispatch(`${this.storeModule}/postItem`,
          {
            external_id: updatedItem.external_id,
            description: updatedItem.description,
            amount: updatedItem.amount,
            date: updatedItem.date,
            contribution: updatedItem.contribution.external_id
          }
        )
        .then(x => { // reload this table
          this.getInstallments()
        })
    }

  }
}
</script>

<style scoped>

</style>
