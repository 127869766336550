<template>
  <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
             :customOptions="customOptions" :getActionPath="getActionPath" :customActions="customActions"
             :parentKey="this.parentKey" :parentValue="this.parentValueString" :editableValues="true"
             v-on:messageEventResult="displayNotification($event)" :itemsPerPage="[10, 25, 50, 75]"
  >
    <template v-slot:form="{close}">
      <AgreementDocsForm @save="onItemCreated(close)" @close="close" />
    </template>

    <template v-slot:item.creation_datetime="{ item }">
      <span>{{ item.creation_datetime | detectAndFormatDate }}</span>
    </template>

    <template v-slot:item.file="{ item }">
      <v-tooltip color="black" dark left>
        <template v-slot:activator="{ on }">
          <v-btn v-show="item.file" :href="item.file" color="primary" target="_blank" fab small dark v-on="on" class="my-3" >
            <v-icon>fas fa-file-download</v-icon>
          </v-btn>
        </template>
        <span>{{ valueToShow(item.file) }}</span>
      </v-tooltip>

    </template>
  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import AgreementDocsForm from '../forms/AgreementDocsForm'
import { RESPONSE_LEVEL } from '@/variables'
import { createErrorNotification, createSuccessNotification, createWarningNotification } from '@/lib/unnotificationsqueue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AgreementDocs',

  components: { CrudTable, AgreementDocsForm },

  props: {
    parentValue: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      parentKey: 'referenced_item',
      storeModule: 'agreementDocs',
      customOptions: {
        'sortBy': ['id'],
        'sortDesc': [false]
      }
    }
  },

  computed: {
    ...mapState('agreementDocs', ['headers', 'filterItems', 'customActions', 'getActionPath']),
    parentValueString () {
      return this.parentValue.toString() // CrudTable should accept integers
    }
  },

  methods: {
    ...mapActions({ addNotification: 'addNotification' }),
    ...mapActions('agreementDocs', [
      'getItem',
      'getDocument',
      'reloadItemList'
    ]),

    onItemCreated (close) {
      this.addNotification(createSuccessNotification('Se ha guardado el documento con éxito'))
      this.reloadItemList()
      close()
    },

    displayNotification (eventOfAction) {
      if (eventOfAction.status === RESPONSE_LEVEL.SUCCESS) {
        this.addNotification(createSuccessNotification(eventOfAction.messages))
      } else if (eventOfAction.status === RESPONSE_LEVEL.WARNING) {
        this.addNotification(createWarningNotification(eventOfAction.messages))
      } else if (eventOfAction.status === RESPONSE_LEVEL.ERROR) {
        this.addNotification(createErrorNotification(eventOfAction.messages))
      }
      this.reloadItemList()
    },

    valueToShow (file) {
      return file && file.split('/').slice(-1)[0]
    }
  }
}
</script>
