<template>
  <div>
    <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
               @save="onContributionEdition" :customActions="customActions" :parentKey="this.parentKey"
               :parentValue="this.parentValue" :customOptions="customOptions"
               :editableValues="editableValues" v-on:item-deleted="onItemDeleted" :isFormLoading="isFormLoading">

      <template v-slot:form="{close}">
        <ContributionForm @save="afterCreation()" @close="close" :loading.sync="isFormLoading"/>
      </template>

      <template v-slot:item.installments="{item}">
        <v-btn color="primary" dark class="elevation-0" @click="showInstallments(item)">
          Ver pagos
        </v-btn>
      </template>
    </CrudTable>
    <v-dialog max-width="800px" v-model="installmentsDialog" @close="closeInstallmentsDialog">
      <v-card>
        <v-card-title>
          Estructura de pagos
        </v-card-title>
        <v-card-text>
          <v-container>
            <ContributionInstallmentTable parentKey="contribution__external_id" :parentValue="editedItem.external_id" :key="componentKey"/>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions, mapState } from 'vuex'
import ContributionForm from '../forms/ContributionForm'
import Lget from 'lodash/get'
import Lset from 'lodash/set'
import ContributionInstallmentTable from './ContributionInstallmentTable'

export default {
  name: 'ContributionTable',
  components: { ContributionInstallmentTable, ContributionForm, CrudTable },

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'contributions',
      editedItem: -1,
      filterItems: [],
      customOptions: {
        'sortBy': ['type__name'],
        'sortDesc': [false]
      },
      installmentsDialog: false,
      headers: [
        { text: 'Tipo', value: 'type.name', align: 'end' },
        {
          text: 'Portfolio',
          value: 'props.portfolio',
          align: 'end',
          hide: false,
          selector: true,
          returnObject: true,
          formatNumber: false,
          labelName: '',
          modelToUse: 'contributions.categories'
        },
        { text: 'Concepto', value: 'name', align: 'end', editable: true, hide: false, formatNumber: false, type: 'string' },
        {
          text: 'Importe',
          value: 'amount',
          align: 'end',
          editable: true,
          type: 'float',
          hide: false,
          formatNumber: true,
          total: 'total_contributions'
        },
        {
          text: 'Estructura de pagos',
          value: 'installments',
          formatNumber: false,
          align: 'end'
        },
        {
          text: 'Subida ficheros',
          value: 'contribution_file',
          formatNumber: false,
          align: 'end',
          hide: false,
          isFile: true
        }
      ],
      customActions: [
        {
          name: 'duplicate',
          text: 'duplicar seleccionado(s)',
          icon: 'fa-copy',
          action: this.duplicateSelected
        }
      ],
      componentKey: 0,
      isFormLoading: false
    }
  },

  computed: {
    ...mapState('contributions', ['extra_fields', 'categories']),
    ...mapState('agreements', { currentAgreement: 'currentItem' })
  },

  created () {
    this.getValidPortfolios()
  },
  methods: {
    Lget: Lget,
    ...mapActions('contributions', ['getValidPortfolios', 'reloadItemList', 'postManyItems']),
    ...mapActions('agreementlines', { reloadAgreementLines: 'reloadItemList' }),
    ...mapActions('endorsements', ['getValidContributions']),

    afterCreation () {
      this.reloadItemList()
      this.reloadAgreementLines()
      this.getValidContributions(this.currentAgreement.external_id)
    },

    onItemDeleted () {
      this.reloadItemList()
      this.$emit('updateagreement')
      this.reloadAgreementLines()
      this.getValidContributions(this.currentAgreement.external_id)
    },

    onContributionEdition (data) {
      if (data.value.type) {
        let formData = new FormData()

        // The expected post is a form key-value with the file and the external id to update
        formData.append('contribution_file', data.value)
        formData.append('external_id', data.item.external_id)

        // Post the data from the crud factory method postFormItem
        this.$store
          .dispatch(`${this.storeModule}/postFormItem`, formData
          ).then(response => {
            console.log(response)
            this.reloadItemList()
          }).catch(error => {
            console.error({ error })
          })
      } else {
        let updatedItem = Lset(data.item, data.header, data.value)
        this.$store
          .dispatch(`${this.storeModule}/postItem`,
            {
              external_id: updatedItem.external_id,
              name: updatedItem.name,
              description: updatedItem.description,
              agreement: this.currentAgreement.external_id,
              amount: updatedItem.amount,
              props: {
                portfolio: Lget(updatedItem, 'props.portfolio', ''),
                ...updatedItem.props
              },
              start_date: updatedItem.start_date,
              contribution_file: undefined, // Endorsement_file is posted separately
              end_date: updatedItem.end_date
            }
          )
          .then(x => { // reload this table and contributions
            this.getValidContributions(this.parentValue)
            this.reloadItemList()
            this.$emit('updateagreement')
            this.reloadAgreementLines()
          })
      }
    },

    duplicateSelected (data) {
      // reset external_id because if not update item and dont duplicate it
      let itemsToPost = data.items
        .map(item => ({
          ...item,
          agreement: item.agreement.external_id.toString(),
          creditor: item.creditor ? item.creditor.external_id : undefined,
          type: item.type.name,
          id: undefined,
          external_id: undefined
        }))
      let isConfirmed = confirm('¿Seguro que quieres duplicar los elementos seleccionados?')
      if (isConfirmed) {
        // Manual setLoading is to be removed with GenericApiView migration of actions
        this.$store.commit(`${this.storeModule}/setLoading`, true)
        this.postManyItems(itemsToPost)
          .then(() => {
            this.reloadItemList()
            this.$emit('updateagreement')
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.$store.commit(`${this.storeModule}/setLoading`, false)
          })
      }
    },

    closeInstallmentsDialog () {
      this.installmentsDialog = false
      this.editedItem = {}
    },
    showInstallments (item) {
      this.editedItem = Object.assign({}, item)
      this.installmentsDialog = true
      this.forceReload()
    },
    forceReload () {
      this.componentKey++
    }
  }
}
</script>

<style scoped>

</style>
