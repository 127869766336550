export default {
  name: 'ExpandableTable',
  props: {
    storeModule: {
      type: String,
      description: 'Gets loading state, item count and locale prefix from respective store',
      default: null
    },
    /** loadingProp:  */
    loadingProp: {
      type: Boolean,
      description: 'only valid if storeModule is not provided, to control loaders',
      default: null
    },
    headerFields: {
      type: Array,
      description: 'Table header fields',
      default: () => [
        { text: 'Fecha', value: 'history_date' },
        { text: 'Usuario', value: 'changed_by' }
      ]
    },
    expandedHeaderFields: {
      type: Array,
      description: 'Expandable items header fields',
      default: () => [
        { text: 'Campo', value: 'field' },
        { text: 'Valor anterior', value: 'old' },
        { text: 'Valor asignado', value: 'new' }
      ]
    },
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      default: 'index'
    },
    itemsPerPage: {
      type: Array,
      default: () => [5, 10, 20]
    },
    height: {
      type: String,
      default: null
    },
    isHistory: {
      type: Boolean,
      description: 'Used to subtract the first empty element from backend history library',
      default: false
    },
    localePrefix: {
      type: String,
      description: 'Used by history tables to map changed fields to a vue-i18n nested object (See AgreementHistory.vue)',
      default: ''
    }
  },
  data () {
    return {
      expandedItems: [],
      tableOptions: {}
    }
  },
  computed: {
    /**
   * Returns loading state. By default, gets the storeModule's loading, otherwise gets the loadingProp
   * @returns {Boolean}: loading state configured
   */
    loading () {
      return this.storeModule ? this.$store.state[this.storeModule].loading : this.loadingProp
    },

    /**
   * Returns count of currently fetched items
   * @returns {Number}
   */
    itemsCount () {
      return this.items.length
    },

    /**
   * Returns total server count from store, as to implement pagination
   * @returns {Number}
   */
    totalItemsCount () {
      let serverCount = this.$store.state[this.storeModule].count
      return (this.isHistory) ? serverCount - 1 : serverCount
    },

    /**
   * Parses options from datatable and returns query options for API calls to be emitted
   * @returns {Object}
   */
    queryOptions () {
      let page = this.tableOptions.page
      let itemsPerPage = this.tableOptions.itemsPerPage
      return {
        limit: itemsPerPage,
        offset: (page && itemsPerPage) ? (page - 1) * itemsPerPage : 0
      }
    }
  },
  watch: {
    /**
   * Detects datatable pagination or itemsPerPage changes, and emits resulting queryOptions.
   * (To be used in paginated API calls)
   */
    tableOptions: {
      deep: true,
      handler () {
        this.expandedItems = []
        this.$emit('query', this.queryOptions)
      }
    }
  },
  methods: {
    /**
   * Inserts or removes values from expandedItems in order to show or collapse items
   * @param value (int): clicked item to get its index.
   */
    toggleItem (value) {
      const clickedIndex = this.expandedItems.findIndex(item => item.index === value.index)
      if (clickedIndex === -1) {
        this.expandedItems.push(value)
      } else {
        this.expandedItems.splice(clickedIndex, 1)
      }
    },
    parseSnakeCaseToCamel (string) {
      const arr = string.split('_')
      arr.map((item, index) => {
        if (index > 0) arr[index] = item.charAt(0).toUpperCase() + item.slice(1)
      })
      return arr.join('')
    }
  }
}
