<template>
  <div>
    <v-select
      class="pa-2"
      v-model="distributorAction"
      :items="distributorActions"
      item-value=""
      item-text=""
      label="Seleccionar o Simular"
      required
      prepend-icon="fa-question-circle"
      :rules="[ (x) => x? true : 'Este campo es requerido']"
      @change="showDistributorOption"
      :disabled="loadingAgreement"
    />

    <SelectDistributor v-model="currentAgreement.distributor" v-if="currentAgreement.distributor.fake !== true"
                       :hidden="!showSelectDistributor" @save="onSaveDistributor" :loadingAgreement="loadingAgreement"/>

    <v-text-field v-if="currentAgreement.distributor.fake === true"
                  v-model="currentAgreement.distributor.name"
                  label="Cliente"
                  placeholder="Nombre del Cliente"
                  prepend-icon="fa-pencil-alt"
                  :rules="[ (x) => x? true : 'Este campo es requerido']"
                  disabled
    />

    <v-dialog v-model="distributorDialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :hidden="!showCreateDistributor">
          <v-icon left>fa-plus</v-icon>
          Simular Cliente
        </v-btn>
      </template>

      <DistributorForm v-if="distributorDialog" :dialog.sync="distributorDialog" @save="onSaveDistributor"
                       @showAlertAction="(x) => showAlertAction(x)" @close="$emit('close')" creationType="client"
      />
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-dialog>

    <v-dialog v-model="groupDialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :hidden="!showCreateGroup">
          <v-icon left>fa-plus</v-icon>
          Simular set de clientes
        </v-btn>
      </template>

      <DistributorForm v-if="groupDialog" :dialog.sync="groupDialog" @save="onSaveDistributor"
                       @showAlertAction="(x) => showAlertAction(x)" @close="$emit('close')" creationType="group"
      />
      <PrettyAlert :text="textAlert" v-model="showAlert"/>
    </v-dialog>

  </div>

</template>

<script>
import DistributorForm from './forms/DistributorForm'
import SelectDistributor from './forms/SelectDistributor'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import { mapActions, mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'SelectOrCreateDistributor',
  components: { DistributorForm, SelectDistributor, PrettyAlert },
  props: {
    loadingAgreement: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      distributorDialog: false,
      groupDialog: false,
      showSelectDistributor: true,
      showCreateDistributor: false,
      showCreateGroup: false,
      distributorAction: 'Seleccionar',
      distributorActions: ['Simular', 'Seleccionar'],
      showAlert: false,
      textAlert: ''
    }
  },

  computed: {
    ...mapState('agreements', { currentAgreement: 'currentItem' }),
    ...mapState('distributors', { currentDistributor: 'currentItem' })
  },

  methods: {
    ...mapActions('distributors', ['getItem']),
    ...mapActions('agreements', { updateAgreementAction: 'updateCurrentItem' }),

    updateCurrentAgreement (distributorId) {
      return this.getItem(distributorId)
        .then(() => {
          this.updateAgreementAction({
            ...this.currentAgreement,
            distributor: this.currentDistributor
          })
        })
    },
    updateDistributorList () {
      this.updateCurrentAgreement()
      this.distributorAction = 'Seleccionar'
      this.showCreateDistributor = false
      this.showCreateGroup = false
      this.showSelectDistributor = true
    },
    showDistributorOption () {
      if (this.distributorAction === 'Simular') {
        this.showCreateDistributor = true
        this.showCreateGroup = true
        this.showSelectDistributor = false
      } else if (this.distributorAction === 'Seleccionar') {
        this.showCreateDistributor = false
        this.showCreateGroup = false
        this.showSelectDistributor = true
      }
    },
    showAlertAction (text) {
      this.textAlert = text
      this.showAlert = true
    },
    onSaveDistributor (distributorId) {
      if (!isEmpty(this.currentAgreement)) {
        this.updateCurrentAgreement(distributorId)
          .then(() => {
            // emit save to the parent container
            this.$emit('save')
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
