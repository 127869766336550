<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-select
        v-model="levelInternal"
        class="pa-2"
        :items="validLevelsList"
        item-value="value"
        item-text="text"
        label="Selecciona el nivel de cliente *"
        required
        prepend-icon="fa-sitemap"
        :disabled="loadingAgreement"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
      v-if="levelInternal===4"
    >
      <v-autocomplete
        v-model="areaInternal"
        class="pa-2"
        :items="areas"
        item-value="external_id"
        :item-text="formatArea"
        label="Selecciona el área"
        required
        prepend-icon="fa-map-marker-alt"
        return-object
        :disabled="loadingAgreement"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-autocomplete
        v-model="selectedDistributor"
        class="pa-2"
        :items="validDistributors"
        :search-input.sync="query"
        :rules="rules.validDistributor"
        item-value="external_id"
        :item-text="textToShow"
        hide-no-data
        no-filter
        :loading="loading"
        label="Selecciona el cliente *"
        placeholder="Escriba para buscar un cliente"
        required
        return-object
        prepend-icon="fa-truck-loading"
        @change="valueNotEmpty"
        :disabled="!selectedLevel || loadingAgreement"
      />
    </v-col>
  </v-row>
</template>

<script>

import { DISTRIBUTOR_GROUP_LEVEL } from '@/variables.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { isEmpty, isNull, isUndefined } from 'lodash'

export default {
  name: 'SelectDistributor',
  props: ['newDistributor', 'loadingAgreement'],
  data () {
    return {
      loading: false,
      query: '',
      levelInternal: -1,
      selectedDistributor: null,
      selectedLevel: false,
      rules: {
        validDistributor: [ (x) => !isEmpty(x) || !isNull(x) || !isUndefined(x) ? true : 'Distribuidor no válido' ]
      }
    }
  },
  computed: {
    DISTRIBUTOR_GROUP_LEVEL: () => DISTRIBUTOR_GROUP_LEVEL,
    ...mapState('distributors', {
      areas: 'areas',
      items: 'items',
      validDistributors: 'validDistributors',
      validLevels: 'validLevels',
      groupChildren: 'groupChildren',
      currentDistributor: 'currentItem' }
    ),
    ...mapState('agreements', ['currentItem']),
    ...mapGetters('distributors', {
      validLevelsList: 'validLevelsList'
    }),
    /* Note this getters and setters reads and writes the current agreement, this is done since this component is used
         to set the distributor field for new or existing agreements */
    newDistributorInternal: {
      get: function () {
        return this.currentItem.newDistributor
      },
      set: function (value) {
        this.updateCurrentItem(
          {
            ...this.currentItem,
            newDistributor: value,
            distributor: {
              ...this.distributorInternal,
              newName: value
            }
          }
        )
      }
    },
    distributorInternal: {
      get: function () {
        return this.currentItem.distributor
      },
      set: async function (value) {
        await this.updateCurrentItem({
          ...this.currentItem,
          distributor: {
            ...this.distributorInternal,
            ...value
          }
        })
      }
    },
    areaInternal: {
      get: function () {
        return this.distributorInternal.area
      },
      set: function (value) {
        // this.clearValidDistributors()
        this.updateCurrentItem(
          {
            ...this.currentItem,
            distributor: {
              ...this.distributorInternal,
              area: value,
              client: this.newDistributorInternal
            }
          }
        )
        this.setDistributorSelectedArea(value)
        this.selectedLevel = true
      }
    }
  },
  watch: {
    distributorInternal: function (value, oldvalue) {
      // we need to refresh the valid options whenever the
      // distributor changes
      if (get(value, 'external_id', null) !== get(oldvalue, 'external_id', null)) {
      }
    },
    query: function (val) {
      // Items have already been requested or query is empty
      if (this.loading === false && (this.query === null || this.query === '')) {

      } else {
        if (this.selectedDistributor !== null && this.selectedDistributor !== undefined) {
          let queryVal = this.query.split(' ')
          if (this.selectedDistributor.external_id.indexOf(queryVal[0]) === -1 && this.selectedDistributor.name.indexOf(queryVal[2]) === -1) {
            this.getDistributorList()
          }
        }
        if (this.selectedDistributor === null || this.selectedDistributor === undefined) {
          this.getDistributorList()
        }
      }
    },
    validDistributors: async function (value) {
      if (this.validDistributors.length === 1) {
        this.getItem(this.validDistributors[0].external_id)
        this.selectedDistributor = this.validDistributors[0]
        this.selectedDistributor.area = this.currentItem.distributor.area
        await this.updateCurrentItem({
          ...this.currentItem,
          distributor: {
            ...this.selectedDistributor,
            client: this.selectedDistributor
          }
        })
        this.$emit('save')
      }
    },
    levelInternal: function (nVal) {
      this.clearValidDistributors()
      this.updateCurrentItem(
        {
          ...this.currentItem,
          distributor: {
            ...this.distributorInternal,
            level: nVal,
            client: this.newDistributorInternal
          }
        }
      )
      this.selectedLevel = nVal !== 4
    },
    selectedDistributor: function (value) {
      value.area = this.currentItem.distributor.area
      this.updateCurrentItem(
        {
          ...this.currentItem,
          distributor: value
        }
      )
    }
  },
  created () {
    this.initialize()
  },

  methods: {
    ...mapActions('distributors', [
      'getAreas',
      'getValidLevels',
      'getValidDistributors',
      'getItem',
      'clearValidDistributors',
      'setDistributorSelectedArea'
    ]),
    ...mapActions('agreements', ['updateCurrentItem']),
    getDistributorList: debounce(function () {
      let searchValue = this.query
      let params = {
        search: searchValue,
        limit: 50,
        area__external_id: get(this, 'areaInternal.external_id', null),
        level: get(this, 'levelInternal', null)
      }
      params.area__external_id = params.level !== 4 ? null : params.area__external_id
      if ((params.area__external_id != null || params.level !== 4) && params.level != null) {
        this.loading = true
        this.getValidDistributors(params).then(() => {
          this.loading = false
        })
      }
    }, 700),
    initialize () {
      this.areaInternal = {}
      this.getAreas()
      this.getValidLevels()
    },
    formatArea (item) {
      return `${item.name}`
    },

    LIsEmpty (obj) {
      return isEmpty(obj)
    },

    valueNotEmpty (value) {
      if (value !== null && value !== undefined) {
        this.$emit('save', value.external_id)
      }
    },

    textToShow: item => item.external_id + ' - ' + item.name
  }

}

</script>

<style scoped>

</style>
