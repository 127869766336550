<template>
  <v-card>
    <v-form
      ref="distributorForm"
      @submit.prevent="submit"
    >
      <v-card-title v-if="creationType==='client'">
        <span class="headline">Simular cliente</span>
      </v-card-title>
      <v-card-title v-if="creationType==='group'">
        <span class="headline">Simular set de clientes</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="item in distributorFields.filter(d => d.name !== 'external_id')"
              :key="item.name"
              cols="12"
            >
              <v-text-field v-if="item.type==='str'"
                            v-model="newDistributor[item.name]"
                            v-bind:label="item.required ? item.label + ' *' : item.label"
                            :rules="[ (x) => item.required ? (x ? true : 'Este campo es requerido') : true]"
                            class="pa-2"
                            v-bind:prepend-icon="item.icon"
              />

              <v-select v-if="item.name==='level' && creationType==='client'"
                        v-model="newDistributor[item.name]"
                        :items="validRealLevelsList"
                        item-value="value"
                        item-text="text"
                        v-bind:label="item.required ? item.label + ' *' : item.label"
                        required
                        :rules="[ (x) => item.required ? (x || x===0 ? true : 'Este campo es requerido') : true]"
                        v-bind:prepend-icon="item.icon"
              />
              <v-autocomplete
                v-if="item.name==='level' && creationType==='group'"
                v-model="newDistributorGroupInternal"
                class="pa-2 mb-n12"
                :items="validDistributors"
                item-value="external_id"
                :item-text="textToShow"
                hide-no-data
                :search-input.sync="query"
                label="Seleccione los clientes a añadir *"
                placeholder="Escriba para buscar un cliente"
                required
                prepend-icon="fa-truck-loading"
                return-object
                multiple
                chips
                persistent-hint
                small-chips
                :loading="distributorLoading"
              />

              <v-text-field v-if="(distributorData.includes(item.name) || locationLevels.hasOwnProperty(item.name))
              && editabledLocations.includes(item.name)
              && !disabledLocations.includes(item.name)
              && newDistributor.level !== DISTRIBUTOR_GROUP_LEVEL && creationType==='client'"
                              v-model="newDistributor[item.name]"
                              class="pa-2"
                              item-value="external_id"
                              item-text="name"
                              v-bind:label="item.required ? item.label + ' *' : item.label"
                              required
                              :rules="[ (x) => item.required ? (x ? true : 'Este campo es requerido') : true]"
                              v-bind:prepend-icon="item.icon"
              />

              <v-autocomplete v-if="(distributorData.includes(item.name) || locationLevels.hasOwnProperty(item.name))
              && !editabledLocations.includes(item.name)
              && !disabledLocations.includes(item.name)
              && newDistributor.level !== DISTRIBUTOR_GROUP_LEVEL
              && creationType==='client'"
                              v-model="newDistributor[item.name]"
                              class="pa-2"
                              :items="item.name !== 'management' ? locationsByIndex[locationLevels[item.name]] : managements"
                              item-value="external_id"
                              :item-text="item.name === 'management' ? 'external_id' : 'name'"
                              v-bind:label="item.required ? item.label + ' *' : item.label"
                              required
                              :loading="loadingList[item.name]"
                              :rules="[ (x) => item.required ? (x ? true : 'Este campo es requerido') : true]"
                              v-bind:prepend-icon="item.icon"
                              v-on:click="item.name !== 'management' ? getSelectList(item) : null"
              />
              <v-dialog v-if="item.name==='level' && creationType==='group'" v-model="clientsBulkDialog" max-width="700" max-height="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="margin: 0 0 -100px 40px"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Agregar lista de clientes
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline">Agregar lista de clientes</v-card-title>
                  <v-autocomplete
                    v-model="tmpDistributorBulk"
                    class="p-2"
                    style="max-width: 600px; margin: 20px auto 20px auto"
                    :items="validDistributors"
                    item-value="external_id"
                    :item-text="textToShow"
                    hide-no-data
                    :search-input.sync="query"
                    label="Agregue lista de clientes"
                    placeholder="Pegue una lista de clientes que quiera añadir"
                    required
                    prepend-icon="fa-plus"
                    return-object
                    clearable
                    multiple
                    chips
                    persistent-hint
                    small-chips
                    :loading="distributorLoading"
                  />
                  <div class="d-flex">
                    <v-text-field
                      v-model="tmpDistributorBulk.length"
                      label="Clientes encontrados"
                      style="margin: 0 80px"
                      max-width="100"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="tmpNotFoundDistributors.length"
                      label="Clientes no encontrados"
                      style="margin: 0 80px"
                      color="red darken-4"
                      max-width="100"
                      readonly
                      d-flex
                    ></v-text-field>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="p-2 m-4" @click="addBulkList(true)" :disabled="disabledBtn">Añadir clientes</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-textarea
                v-if="item.name==='level' && creationType==='group'"
                outlined
                class="p-2"
                style="max-width: 700px; margin: 90px auto -170px auto"
                name="input-7-4"
                label="Lista de clientes"
                placeholder="Lista de clientes que se agregarán al set de clientes"
                v-model="distributorsChosen"
                readonly
              ></v-textarea>

              <v-textarea
                v-if="item.name==='level' && creationType==='group' && notFoundDistributors.length > 0"
                outlined
                class="p-2"
                style="max-width: 700px; margin: 170px auto -170px auto; border-color: darkred !important"
                color="red darken-4"
                name="input-7-4"
                label="Lista de clientes no encontrados"
                placeholder="Lista de clientes que no se han encontrado"
                v-model="notFoundDistributors"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions class="mr-5">
        <v-spacer/>
        <v-btn
          color="primary"
          @click="save"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { labelsMixin } from '@/apps/core/mixins/cosmos_labels'
import { DISTRIBUTOR_GROUP_LEVEL } from '@/variables.js'
import { union, isEmpty, debounce, isNull } from 'lodash'

export default {
  name: 'DistributorForm',
  mixins: [labelsMixin],

  created () {
    this.initialize()
  },
  props: {
    creationType: String // It will be 'client' or 'group'
  },
  data () {
    return {
      dialog: false,
      search: null,
      clientsBulkDialog: false,
      clientIcon: 'fa-truck-loading',
      message: '',
      loadingList: {
        'area': false,
        'management': false
      },
      distributorLoading: false,
      disabledBtn: true,
      itemGroupList: [],
      distributorBulkList: [],
      distributorData: [
        'management'
        // Additional parameters to create a new distributor
      ],
      disabledLocations: [
        'center'
        // Not available locations in the form
      ],
      editabledLocations: [
        'country',
        'region',
        'province',
        'city',
        'street'
        // indexes are levels to each location
      ],
      locationLevels: {
        'country': 0,
        'region': 1,
        'province': 2,
        'city': 3,
        'area': 4,
        'street': 5,
        'center': 6
        // indexes are levels to each location
      },
      newDistributor: {
        'name': '',
        'description': null,
        'level': null,
        'parent': null,
        'active': true,
        'props': {},
        'city': null,
        'street': null,
        'province': null,
        'country': null,
        'editable': true,
        'management': null
      },
      distributors: [],
      notFoundDistributors: [],
      tmpNotFoundDistributors: [],
      distributorsChosen: '',
      query: ''
    }
  },
  watch: {
    query: function (val) {
      // Items have already been requested or query is empty
      if (!(this.loading === false && (!this.query === null || this.query === ''))) {
        if (!isNull(val) && (val !== undefined) && this.clientsBulkDialog) {
          this.getDistributorsByExtId(val)
        }
        if (!isNull(val) && !this.clientsBulkDialog) {
          this.getDistributorList()
        }
      }
    },
    newDistributorGroupInternal: async function (nVal) {
      this.distributorsChosen = ''
      await nVal.forEach(value => {
        let client = '• ' + value.external_id.trim() + ' — ' + value.name.trim()
        if (!this.distributorsChosen.includes(client)) {
          this.distributorsChosen += client + '\n'
        }
      })
    },
    validDistributors: async function (value) {
      if (this.validDistributors.length === 1) {
        if (!this.newDistributorGroupInternal.includes(this.validDistributors[0])) {
          this.newDistributorGroupInternal.push(this.validDistributors[0])
        }
      }
    }
  },
  computed: {
    ...mapGetters('distributors', ['validLevelsList', 'validRealLevelsList']),
    ...mapState('distributors', {
      locations: 'locations',
      locationsByIndex: 'locationsByIndex',
      validGroupItems: 'validGroupItems',
      currentDistributor: 'currentItem',
      managements: 'managements',
      validDistributors: 'validDistributors'
    }),
    DISTRIBUTOR_GROUP_LEVEL: () => DISTRIBUTOR_GROUP_LEVEL,
    ...mapState('distributors', {
      area: 'area',
      items: 'items',
      validLevels: 'validLevels',
      groupChildren: 'groupChildren',
      currentDistributor: 'currentItem'
    }
    ),
    ...mapState('agreements', ['currentItem']),
    ...mapGetters('distributors', { validLevelsList: 'validLevelsList', validRealLevelsList: 'validRealLevelsList' }),
    validDistributors: function () {
      if (this.distributorInternal.external_id) {
        this.items.concat([this.distributorInternal])
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.distributors = union(this.distributors, this.items)
      return this.distributors
    },
    /* Note this getters and setters reads and writes the current agreement, this is done since this component is used
           to set the distributor field for new or existing agreements */
    newDistributorInternal: {
      get: function () {
        return this.currentItem.newDistributor
      },
      set: function (value) {
        this.updateCurrentItem(
          {
            ...this.currentItem,
            newDistributor: value,
            distributor: {
              ...this.distributorInternal,
              newName: value
            }
          }
        )
      }
    },
    distributorInternal: {
      get: function () {
        return this.currentItem.distributor
      },
      set: async function (value) {
        await this.updateCurrentItem({
          ...this.currentItem,
          distributor: {
            ...this.distributorInternal,
            ...value
          }
        })
      }
    },
    levelInternal: {
      get: function () {
        return this.distributorInternal.level
      },
      set: function (value) {
        // update the distributor external_id null
        this.updateCurrentItem(
          {
            ...this.currentItem,
            distributor: {
              ...this.distributorInternal,
              level: value,
              external_id: null
            }
          }
        )
      }
    },
    areaInternal: {
      get: function () {
        return this.distributorInternal.area
      },
      set: function (value) {
        this.updateCurrentItem(
          {
            ...this.currentItem,
            distributor: {
              ...this.distributorInternal,
              area: value,
              client: this.newDistributorInternal
            }
          }
        )
      }
    },

    newDistributorGroupInternal: {
      get: function () {
        return this.itemGroupList
      },
      set: function (value) {
        this.itemGroupList = value
      }
    },

    tmpDistributorBulk: {
      get: function () {
        return this.distributorBulkList
      },
      set: function (value) {
        this.distributorBulkList = value
      }
    }
  },

  methods: {
    ...mapActions('distributors', ['getAreas', 'getValidLevels', 'getValidItems', 'getItemList']),
    ...mapActions('distributors', {
      getGroupChildren: 'getGroupChildren',
      getValidGroupItems: 'getValidGroupItems',
      getLocations: 'getLocations',
      getLocationsByIndex: 'getLocationsByIndex',
      getManagements: 'getManagements',
      postFormItem: 'postFormItem',
      postManyItems: 'postManyItems',
      updateCurrentDistributor: 'updateCurrentItem',
      getSelectedDistributors: 'getSelectedDistributors',
      createCampaignInternalGrouping: 'createCampaignInternalGrouping'
    }),
    close () {
      this.$emit('update:dialog', false)
      if (this.creationType === 'group') {
        this.newDistributor = {}
        this.newDistributorGroupInternal = []
        this.notFoundDistributors = []
      } else {
        this.$refs.distributorForm.reset()
      }
    },

    initialize () {
      this.getManagements()
    },
    save () {
      if (this.$refs.distributorForm.validate()) {
        /**
         * This function should post first the group to create it. Then we will change the group id reference to all the distributors in
         * the newDistributorGroupInternal.
         * The createInternalGrouping method has been done to don't lose sch-data before the distributor's refactoring.
         */
        // remove null fields to allow do post
        Object.keys(this.newDistributor).forEach(index => (this.newDistributor[index] === null) && delete this.newDistributor[index])

        // When is a group you do not pass the level, so it must be set
        if (this.newDistributor.level === undefined) {
          this.newDistributor.level = 3
        }
        if (this.newDistributor.level === 1) {
          this.newDistributor.level = 4
          this.newDistributor.name = 'CADENA ' + this.newDistributor.name
        }
        if (this.newDistributor.level === 2) {
          this.newDistributor.level = 4
          this.newDistributor.name = 'AGRUPACIÓN ' + this.newDistributor.name
        }
        this.newDistributor.created_from = 1
        this.postFormItem({
          ...this.newDistributor
        })
          .then(r => {
            if (this.newDistributor.level === DISTRIBUTOR_GROUP_LEVEL) {
              let distributorsToUpdate = []
              let distributorsExternal = []
              this.newDistributorGroupInternal.forEach(function callback (currentValue, index, array) {
                distributorsToUpdate.push({ 'external_id': currentValue.external_id, 'group': r.data.external_id, 'level': 4 })
                distributorsExternal.push(currentValue.external_id)
              },
              this.updateChildrenGroup(distributorsToUpdate, r.data.external_id)
              )
              let PostInternalGrouping = { 'grouping_name': this.newDistributor.name, 'grouping_external_id': r.data.external_id, 'internal_distributors': distributorsExternal.join(', ') }
              this.createInternalGrouping(PostInternalGrouping)
            } else {
              this.saveDistributor('Cliente creado', r.data.external_id)
            }
          }
          )
          .catch(err => {
            this.$emit('showAlertAction', 'Error al crear set de clientes')
            console.error(err)
          })
      } else {
        this.$emit('showAlertAction', 'Introduce los campos requeridos')
      }
    },

    saveDistributor (message, distributorId = null) {
      this.newDistributor.external_id = distributorId
      this.updateCurrentDistributor(this.newDistributor)
      this.$emit('save', distributorId)
      this.close()
      this.$emit('showAlertAction', message)
    },
    LIsEmpty (obj) {
      return isEmpty(obj)
    },

    getDistributorList: debounce(async function () {
      let searchValue = this.query
      this.distributorLoading = true
      let params = {
        search: searchValue,
        limit: 50,
        level: 4
      }
      await this.getItemList(params)
      this.distributorLoading = false
    }, 600),

    getDistributorsByExtId: debounce(async function (query) {
      this.distributorLoading = true
      let externalIds = this.getDistributorsSearchParams(query)
      let params = {
        search: externalIds,
        level: 4
      }
      await this.getSelectedDistributors(params).then(res => {
        this.distributorBulkList = res
        let foundDistributors = []
        res.forEach(elem => {
          foundDistributors.push(elem.external_id)
        })
        externalIds['external_id'].forEach(val => {
          if (!foundDistributors.includes(val)) {
            this.tmpNotFoundDistributors.push(val)
          }
        })
      })
      this.distributorLoading = false
      this.disabledBtn = false
    }, 600),

    saveChildrenGroup (externalId) {
      // Create distributor child items with name and external_id of distributor group
      let itemsToPost = []
      for (let index in this.itemGroupList) {
        let dataItem = { name: this.itemGroupList[index], level: -1, group: externalId }
        itemsToPost.push(dataItem)
      }
      try {
        this.postManyItems(itemsToPost)
          .then(r => this.saveDistributor('Clientes creados', externalId))
          .catch(err => {
            this.$emit('alert', 'Error al crear cliente')
            console.error(err)
          })
      } catch (e) {
        console.log('Exception post items, error: ', e.toString())
      }
    },
    updateChildrenGroup (distributorsToUpdate, groupId) {
      /**
         * This function will post to distributors to update the group of the distributors passed in the array distributorsToUpdate
         */
      try {
        this.postManyItems(distributorsToUpdate)
          .then(r => this.saveDistributor('Clientes actualizados', groupId))
          .catch(err => {
            this.$emit('alert', 'Error al actualizar el grupo de los clientes')
            console.error(err)
          })
      } catch (e) {
        console.log('Exception post items, error: ', e.toString())
      }
    },
    // Temporal patch to don't lose sch client's sets data
    createInternalGrouping (PostInternalGrouping) {
      try {
        this.createCampaignInternalGrouping(PostInternalGrouping)
      } catch (e) {
        console.log('Exception creating InternalGrouping, error: ', e.toString())
      }
    },
    getSelectList: debounce(async function (item) {
      this.loading(item.name)

      let params = {}
      params['locationIndex'] = this.locationLevels[item.name]
      await this.getLocationsByIndex(params)

      try {
        await this.getLocationsByIndex(params)
      } catch (e) {
        console.log(e)
      }
      this.loading(item.name)
    }, 500),
    textToShow: item => item.external_id + ' — ' + item.name,
    loading: function (itemName) {
      this.loadingList[itemName] = !this.loadingList[itemName]
    },
    getDistributorsSearchParams (query) {
      let result = []
      let values = query.includes(',') ? query.split(', ') : query.split(' ')
      values.forEach(el => {
        let arr = el.split(' ')
        let value = arr[0].padStart(10, '0')
        if (value !== '0000000000' && !result.includes(value)) {
          result.push(value)
        }
      })
      return { external_id: result }
    },
    async addBulkList (val) {
      if (val === true) {
        await this.distributorBulkList.forEach(elem => {
          if (!this.itemGroupList.includes(elem)) {
            this.newDistributorGroupInternal.push(elem)
          }
        })
        this.tmpNotFoundDistributors.forEach(value => {
          let notClient = '• ' + value
          if (!this.notFoundDistributors.includes(notClient)) {
            this.notFoundDistributors += notClient + ' \n'
          }
        })
      }
      this.tmpNotFoundDistributors = []
      this.distributorBulkList = []
      this.disabledBtn = true
      this.clientsBulkDialog = false
    }
  }
}

</script>

<style scoped>

</style>
