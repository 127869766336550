<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('general.management', 2) }}</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="currentItem.distributor.managements !== undefined?
            currentItem.distributor.managements.map(el => ({'name': el})): []"
            class="elevation-1"
            >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-autocomplete
      class="pa-2"
      v-if="getManagementListLength() > 0"
      :label="$tc('general.management', 1)"
      readonly
      multiple
      chips
      @click:append="dialog = true"
      :append-icon="getManagementListLength() > 4? 'fa-eye': null"
      prepend-icon="fa-user-friends"
      v-model="currentItem.distributor.managements"
      :items="currentItem.distributor.managements"
    >
      <template v-slot:selection="data">
        <v-chip
          v-if="data.index < 4"
          v-bind="data.attrs"
          :input-value="data.selected">
          {{ data.item }}
        </v-chip>
        <label
          style="margin: 4px !important; height: 20px!important;color:#9e9e9e !important;"
          v-else-if="data.index === 4"
        >
          (+ {{getManagementListLength() - 4}} Otros)
        </label>
      </template>
    </v-autocomplete>
    <v-text-field
      v-else
      class="pa-2"
      :label="$tc('general.management', 1)"
      :value="$tc('general.management', 0)"
      prepend-icon="fa-user-friends"
      readonly
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isNull, isUndefined } from 'lodash'

export default {
  name: 'Managements',
  data () {
    return {
      dialog: false,
      headers: [
        { text: 'Nombre', value: 'name', hide: false }
      ]
    }
  },
  computed: {
    ...mapState('agreements', [
      'currentItem'
    ])
  },
  methods: {
    getManagementListLength () {
      const distributor = this.currentItem.distributor
      if (!isUndefined(distributor.managements) && !isNull((distributor.managements))) {
        return distributor.managements.length
      }
      return 0
    }
  }
}
</script>

<style scoped>

</style>
