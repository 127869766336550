<template>
  <v-card class="pa-2 overflow-hidden" v-if="distributor">

    <v-toolbar flat dense>
      <v-toolbar-title class="pa-2">
        <v-icon class="mx-2">fa-truck</v-icon>
        <span class="headline">Cliente</span>
      </v-toolbar-title>

      <v-dialog v-if="editableValues" v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn icon x-small class="ml-2" v-on="on" title="Cambiar el cliente del acuerdo">
            <v-icon>fa-edit</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Cambiar el cliente del acuerdo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <SelectOrCreateDistributor @save="onSaveDistributor" @close="dialog=false"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="distributor.level === 1 ||
                      distributor.level === 2 ||
                      distributor.level === 3"
                v-model="dialogChain" max-width="1200px"
                style="max-height: 600px;overflow-y: scroll;">
        <template v-slot:activator="{ on }">
          <v-btn icon x-small class="ml-2" color="" v-on="on" title="Clientes que pertenecen a la cadena">
            <v-icon>fa-window-restore</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Clientes</span>
          </v-card-title>
          <v-card-text>
            <CrudTable
              :key="onChangeClientReload"
              :showActionsPanel="false" :addItems="false"
              :storeModule="clientsStoreModule" :headers="headers"
            >
              <template v-slot:item.level="item">
                {{getLevelNameById(item.item.level, validLevels)}}
              </template>

              <template v-slot:item.beneficiary.name="item">
                <Beneficiary
                    class="mt-5"
                    style="width: 400px"
                    :agreement__external_id="agreement.external_id"
                    :client="item.item"
                    :editableValues="editableBeneficiary" />
              </template>
            </CrudTable>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text v-if="Object.keys(distributor).length === 0">
      No hay Distribuidores asociados a este acuerdo
    </v-card-text>

    <v-card-text v-else>
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="4" v-for="subItem in distributorFieldsToShow(distributor)" :key="subItem.name">
            <template v-if="!isLoadingDistributor">
              <v-text-field
                readonly filled rounded hide-details
                class="input-deadpan"
                :label="subItem.label"
                :value="subItem.name !== 'level'? getNameOrValue(distributor, subItem) : getLevelNameById(distributor[subItem.name], validLevels)"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="pr-5 pt-1">{{subItem.icon}}</v-icon>
                </template>
              </v-text-field>
            </template>
            <template v-else>
              <v-skeleton-loader filled rounded type="card-heading" :height="'100%'" :width="'100%'"/>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { labelsMixin } from '@/apps/core/mixins/cosmos_labels'

import SelectOrCreateDistributor from './SelectOrCreateDistributor'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import Beneficiary from './Beneficiary'

import { isNull, isUndefined } from 'lodash'
import Lget from 'lodash/get'

export default {
  name: 'DistributorList',
  components: { SelectOrCreateDistributor, CrudTable, Beneficiary },
  mixins: [labelsMixin],
  props: ['editableValues', 'editableBeneficiary'],

  data () {
    return {
      dialog: false,
      dialogChain: null,
      distributor: null,
      clientsStoreModule: 'agreementClients',
      headers: [
        { text: 'Identificador', value: 'external_id', align: 'start', hide: false },
        { text: 'Nombre', value: 'name', align: 'start', hide: false },
        { text: 'Descripción', value: 'description', align: 'start', hide: false },
        { text: 'Nivel', value: 'level', align: 'start', hide: false },
        { text: 'Calle', value: 'street.name', align: 'start', hide: false },
        { text: 'Área', value: 'area.name', align: 'start', hide: false },
        { text: 'Gerencia', value: 'managements', hide: false },
        { text: 'Centro', value: 'center.name', align: 'start', hide: false },
        { text: 'Ciudad', value: 'city.name', align: 'start', hide: false },
        { text: 'Provincia', value: 'province.name', align: 'start', hide: false },
        { text: 'Nombre de Beneficiario', value: 'beneficiary.name', align: 'start', hide: false },
        { text: 'Tipo establecimiento', value: 'establishment_type_name', align: 'start', hide: false }
      ],
      onChangeClientReload: false,
      isLoadingDistributor: false
    }
  },

  computed: {
    ...mapState('agreements', {
      agreement: 'currentItem'
    }),
    ...mapState('distributors', ['validLevels', 'groupChildren']),

    /** distributor fields is defined in cosmos_labels. Here we return a function to
       *  discard empty fields and the external_id */
    distributorFieldsToShow () {
      return (item) => this.distributorFields.filter(field => item[field.name])
    }
  },

  watch: {
    dialog: function () {
      this.isLoadingDistributor = this.dialog
    }
  },

  methods: {
    ...mapActions('distributors', {
      getGroup: 'getGroupChildren',
      reloadDistributors: 'reloadItemList'
    }),
    ...mapActions('agreementClients', [
      'getItemList'
    ]),
    ...mapMutations('agreementClients', [
      'clearItems'
    ]),
    Lget: Lget,
    getNameOrValue: function (distributor, item) {
      let value = distributor[item.name].name || distributor[item.name]
      value = typeof value === 'string' || typeof value === 'number' ? value.toUpperCase() : null
      return value
    },
    getLevelNameById: function (id, validLevels) {
      let name = Object.keys(validLevels).filter(function (key, value) {
        return value === id
      })[0]
      return name !== undefined ? name.toUpperCase() : name
    },
    onSaveDistributor () {
      this.$emit('save')
      this.clearItems()
      this.distributor = this.agreement.distributor
      this.onChangeClientReload = !this.onChangeClientReload
      // close dialog on save
      this.dialog = false
    },
    textToShow (item) {
      if (isNull(item) || isUndefined(item)) {
        if (this.editableValues) {
          return null
        }
        return 'Sin beneficiario asociado'
      }

      return item.external_id + ' - ' +
        (!(isNull(item.name) || isUndefined(item.name)) ? item.name : '[Sin Nombre]') + ' - ' +
        (!(isNull(item.center) || isUndefined(item.center)) ? item.center.name : '[Sin Centro]') + ' - ' +
        (!(isNull(item.props) || isUndefined(item.props)) ? (!(isNull(item.props.route) || isUndefined(item.props.route))
          ? ((item.props.route === '') ? '[Sin Ruta]' : item.props.route) : '[Sin Ruta]') : '[Sin Ruta]')
    },
    getAgreementDistributor (agreement) {
      this.distributor = agreement.distributor
      this.distributor['establishment_type_name'] = this.distributor.props.establishment_type_name
    }
  },

  mounted () {
    this.getAgreementDistributor(this.agreement)
  }
}
</script>

<style scoped>

</style>
