import get from 'lodash/get'

export const labelsMixin = {

  data () {
    return {
      validStatusKeys: [0, 1, 2, 3, 4, 5, 7],
      orderedStatusesIcons: [
        { icon: 'fa-sticky-note' },
        { icon: 'fa-paper-plane' },
        { icon: 'fa-check-square' },
        { icon: 'fa-minus-circle' },
        { icon: 'fa-biking' },
        { icon: 'fa-hourglass-end' },
        { icon: '' },
        { icon: 'fa-clock' }
      ],
      distributorFields: [
        { name: 'external_id', label: 'Identificador', icon: 'fa-external-link-square-alt', type: 'str', required: true },
        { name: 'name', label: 'Nombre', icon: 'fa-user', type: 'str', required: true },
        { name: 'description', label: 'Descripción', icon: 'fa-sticky-note', type: 'str', required: false },
        { name: 'level', label: 'Nivel de cliente', icon: 'fa-sitemap', type: 'select', required: true },
        { name: 'country', label: 'País', icon: 'fa-globe-europe', type: 'select', required: false },
        { name: 'area', label: 'Área', icon: 'fa-map-marker', type: 'select', required: true },
        { name: 'management', label: 'Gerencia', icon: 'fa-map-pin', type: 'select', required: true },
        { name: 'province', label: 'Provincia', icon: 'fa-flag', type: 'select', required: false },
        { name: 'city', label: 'Ciudad', icon: 'fa-city', type: 'select', required: false },
        { name: 'street', label: 'Calle', icon: 'fa-road', type: 'select', required: false },
        { name: 'establishment_type_name', label: 'Tipo de establecimiento', icon: 'fa-road', type: 'select', required: false }
      ]
    }
  },

  methods: {
    settlementTypeFromSpanish2English (k) {
      return get({
        'Mensual': 'monthly',
        'Trimestral': 'quarterly',
        'Semestral': 'biannual',
        'Anual': 'yearly'
      }, k, '')
    },
    settlementTypeFromEnglish2Spanish (k) {
      return get({
        'monthly': 'Mensual',
        'quarterly': 'Trimestral',
        'biannual': 'Semestral',
        'yearly': 'Anual'
      }, k, '')
    },
    agreementPlanTypeDict (k) {
      return get({
        'Plan Alpha': 'alpha',
        'alpha': 'Plan Alpha',
        'Plan Delta': 'delta',
        'delta': 'Plan Delta',
        'Servicios Digitales': 'digital_services',
        'digital_services': 'Servicios Digitales',
        'Mayoristas': 'wholesalers',
        'wholesalers': 'Mayoristas',
        'Sin Especificar': '',
        '': 'Sin Especificar'
      }, k, '')
    },
    getStatusLabelByIndex: function (statusKey) {
      return get({
        0: 'Borrador',
        1: 'Enviado',
        2: 'Aprobado',
        3: 'Rechazado',
        4: 'En vigor',
        5: 'Vencido',
        7: 'Pdte. Revisión'
      }, statusKey, `N/A (${statusKey})`)
    },
    getStatusLabelIndexByName: function (statusName) {
      return get({
        'Borrador': 0,
        'Enviado': 1,
        'Aprobado': 2,
        'Rechazado': 3,
        'En vigor': 4,
        'Vencido': 5,
        'Pdte. Revisión': 7
      }, statusName, -1)
    }
  }
}
