<template>
  <div>
    <v-text-field
      v-if="!editableValues"
      class="pa-2"
      :value="beneficiary"
      label="Beneficiario de rappel total"
      :prepend-icon="usePrependIcon? 'fa-receipt': ''"
      readonly
    />
    <v-autocomplete
      v-else
      v-model="agreementBeneficiaries"
      :search-input.sync="queryValidBeneficiaries"
      :items="localValidBeneficiaries"
      :item-text="textToShow"
      item-value="name"
      @change="updateBeneficiary"
      label="Beneficiario de rappel total"
      required
      return-object
      :prepend-icon="usePrependIcon? 'fa-receipt': ''"
    />
  </div>
</template>

<script>
import { isNull, isUndefined, debounce, isArray, isPlainObject } from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'Beneficiary',
  props: {
    client: Object,
    editableValues: Boolean,
    usePrependIcon: Boolean,
    agreement__external_id: String
  },
  data: () => {
    return {
      queryValidBeneficiaries: '',
      loadingValidBeneficiaries: false,
      agreementBeneficiaries: null,
      localValidBeneficiaries: []
    }
  },
  watch: {
    queryValidBeneficiaries (val) {
      // Items have already been requested or query is empty
      if (this.loadingValidBeneficiaries === false && (val === null || val === '')) {

      } else {
        this.fetchValidBeneficiaries()
      }
    },

    // Everytime a beneficiary is updated, should be
    // set to the v-autocomplete component
    client () {
      this.setBeneficiary()
    }
  },
  computed: {
    beneficiary () {
      const defaultText = 'Sin beneficiario asociado'
      const isValidBeneficiary = !(
        isNull(this.agreementBeneficiaries) ||
        isUndefined(this.agreementBeneficiaries))
      return isValidBeneficiary ? (
        this.textToShow(this.agreementBeneficiaries) || defaultText) : defaultText
    }
  },
  methods: {
    ...mapActions('agreements', [
      'postBeneficiaryClient',
      'getValidBeneficiariesLocal'
    ]),

    updateBeneficiary () {
      this.postBeneficiaries(this.agreementBeneficiaries)
    },

    async postBeneficiaries (beneficiaries) {
      const tmp = beneficiaries
      await this.postBeneficiaryClient(((isArray(tmp) ? tmp : [tmp]) || []).map(el => {
        if (!(isNull(el) || isUndefined(el))) {
          return {
            'beneficiary': el.external_id,
            'client': this.client.external_id,
            'agreement': this.agreement__external_id
          }
        }
      }))
      this.$emit('postedBeneficiary')
    },

    fetchValidBeneficiaries: debounce(function () {
      if (this.queryValidBeneficiaries.length > 0) {
        this.loadingValidBeneficiaries = true
        const tmp = this.queryValidBeneficiaries.split(' - ')
        this.getValidBeneficiariesLocal({ search: tmp[0].trimEnd(), limit: 50 })
          .then((results) => {
            this.localValidBeneficiaries = results
            this.loadingValidBeneficiaries = false
          })
      }
    }, 500),

    /**
     * This method does set the beneficiary to v-autocomplete component
     * **/
    setBeneficiary () {
      if (!(isUndefined(this.client) || isNull(this.client)) && isPlainObject(this.client.beneficiary)) {
        this.agreementBeneficiaries = this.client.beneficiary
        this.localValidBeneficiaries = [this.client.beneficiary]
      }
    },

    textToShow (item) {
      return item.external_id + ' - ' +
        (!(isNull(item.name) || isUndefined(item.name)) ? item.name : '[Sin Nombre]') + ' - ' +
        (!(isNull(item.center) || isUndefined(item.center)) ? item.center.name : '[Sin Centro]') + ' - ' +
        (!(isNull(item.props) || isUndefined(item.props)) ? (!(isNull(item.props.route) || isUndefined(item.props.route))
          ? ((item.props.route === '') ? '[Sin Ruta]' : item.props.route) : '[Sin Ruta]') : '[Sin Ruta]')
    }
  },
  created () {
    // When the component is created and receive a non null client,
    // the beneficiary should be set.
    this.setBeneficiary()
  }
}
</script>

<style scoped>

</style>
