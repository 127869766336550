import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'

import ExpandableTable from '@/apps/core/components/ExpandableTable/ExpandableTable.vue'
import { formatDateAndTime } from '@/apps/core/helpers/utils'
import { createNotification } from '@/lib/unnotificationsqueue'

@Component({
  components: {
    ExpandableTable
  },
  props: {
    parentValue: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('agreementHistory', ['items'])
  }
})

class AgreementHistory extends Vue {
  storeModule = 'agreementHistory'

  /** No need to fetchHistory() at mounted: ExpandableTable's tableOptions initialization triggers the query event */

  /**
   * Returns the parsed list from the agreement history API
   * @returns {list}
   */
  get historyList () {
    return this.items.map((item, index) => {
      let parsedProps = []
      let propsIndex = -1
      item.changed_fields.forEach((changedField, index) => {
        if (changedField.field === 'props') {
          parsedProps = this.parseProps(changedField)
          propsIndex = index
        }
      })
      if (parsedProps.length) {
        item.changed_fields.splice(propsIndex, 1)
        item.changed_fields.push(...parsedProps)
      }
      this.parseAllChangedFields(item.changed_fields)
      return {
        ...item,
        index,
        history_date: formatDateAndTime(item.history_date)
      }
    })
  }

  /**
   * Fetches history API after triggered by table's query-options changes
   * @param query: query-params to use in API call obtained from table's event
   */
  async fetchHistory (query = {}) {
    try {
      await this.$store.dispatch(`${this.storeModule}/getHistory`, {
        id: this.parentValue,
        query
      })
    } catch (error) {
      await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
    }
  }

  /**
   * If there are props changes in the history, it parses and returns such props as an array to be inserted as regular fields.
   * @param changedFields (object): Reference to the changedField with prop changes.
   * @returns {Array}
   */
  parseProps (changedField) {
    let parsedProps = []
    const oldProps = changedField.old
    const newProps = changedField.new
    Object.keys({ ...oldProps, ...newProps }).forEach((prop) => {
      let oldPropValue = oldProps[prop] || '-'
      let newPropValue = newProps[prop] || '-'
      parsedProps.push({ field: prop, old: oldPropValue, new: newPropValue })
    })
    return parsedProps
  }

  /**
   * Checks for null values in all changed fields to insert a '-'.
   * @param changedFields (object): Reference to the item where changes are overwritten.
   */
  parseAllChangedFields (changedFields) {
    changedFields.forEach((changedField) => {
      if (changedField.old === null) changedField.old = '-'
      if (changedField.new === null) changedField.new = '-'
    })
  }

  async beforeDestroy () {
    this.$store.commit(`${this.storeModule}/clearItems`)
    await this.$store.dispatch('dismissNotifications')
  }
}

export default AgreementHistory
