<template>
  <v-card>
    <v-form @submit.prevent="submit" ref="form">
      <v-card-title>
        <span class="headline">Nueva garantía</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                prepend-icon="fa-tag"
                :items="endorsementTypeList"
                item-value="external_id"
                item-text="name"
                label="Seleccione tipo de aportación"
                required :rules="rules.empty"
                v-model="selectedEndorsementType"
              />
            </v-col>
              <v-col cols="6" md="6">
              <v-select
                multiple
                :items="validContributions"
                item-value="external_id"
                item-text="name"
                label="Aportaciones"
                required :rules="rules.empty"
                v-model="selectedContributions"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="saveForm()">Guardar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import range from 'lodash/range'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EndorsementForm',
  components: {},
  created () {
    this.initialize()
  },
  props: {
    editedIndex: Number
  },
  data () {
    return {
      loading: true,
      selectedEndorsementType: null,
      selectedContributions: null,
      numLinesToCreate: 1,
      editingErrors: {},
      rules: {
        empty: [val => this.isEmpty(val)]
      }

    }
  },
  computed: {
    ...mapState('agreements', { currentAgreement: 'currentItem' }),
    ...mapState('endorsementTypes', { endorsementTypeList: 'items', validContributions: 'aportaciones' }),
    ...mapState('endorsements', ['validContributions']),

    itemListToSend () {
      return range(this.numLinesToCreate).map(idx => ({
        endorsement_type: this.selectedEndorsementType,
        agreement: this.currentAgreement.external_id,
        created_from: 1
      })
      )
    }
  },
  methods: {
    ...mapActions('endorsements', ['postManyItems']),
    ...mapActions('endorsementTypes', { getEndorsementTypeList: 'getItemList' }),

    initialize () {
      this.getEndorsementTypeList()
        .then(
          () => { this.loading = false }
        )
    },

    isEmpty (value) {
      if (!value && value !== 0) {
        return 'Este campo es requerido'
      } else {
        return true
      }
    },

    closeForm () {
      this.$emit('close')
    },

    saveForm () {
      if (this.$refs.form.validate()) {
        this.postManyItems(this.itemListToSend).then((res) => {
          this.$emit('save')
          this.$store.dispatch('old-endorsements/postItem', {
            agreement: this.currentAgreement.external_id,
            contributions: this.selectedContributions,
            endorsement_type: this.selectedEndorsementType,
            external_id: res.data['identification_ids'][0]
          })
          this.$refs.form.reset()
          this.closeForm()
        })
      }
    }
  },

  updated () {
    this.$refs.form.resetValidation()
  }
}
</script>

<style scoped>

</style>
