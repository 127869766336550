<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-textarea
            dense
            outlined
            no-resize
            hide-details
            class="assessment-input"
            name="assessment_input"
            v-model="assessmentInput"
            :label="assessment_label"
            :append-outer-icon="assessment_icon"
            :color="assessment_color"
            :disabled="!editable"
            :readonly="!editable"
            height="18.5rem"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'

import apiClient from '@/lib/unlogin/store/apiclient'
import { requestsMonitorsMixin } from '@/apps/core/mixins/requests_monitors'

export default {
  name: 'Assessment',
  props: {
    editable: Boolean
  },
  mixins: [requestsMonitorsMixin],

  created () {
    this.debouncedSubmitAssessment = debounce(this.submitAssessment, 1000)
  },
  mounted () {
    this.assessmentInput = this.currentItemAssessment
  },

  data () {
    return {
      assessmentInput: null,
      assessment_icon: 'fa-pen',
      assessment_color: 'primary'
    }
  },

  watch: {
    currentItemAssessment (value) {
      if (!this.isPendingRequests && this.assessmentInput !== value) {
        this.debouncedSubmitAssessment()
      }
    },
    assessmentInput (value, oldValue) {
      if (!this.isPendingRequests && oldValue !== null) {
        this.debouncedSubmitAssessment()
      }
    }
  },

  computed: {
    ...mapState('agreements', ['currentItem']),
    currentItemAssessment () {
      return this.currentItem.assessment
    },
    assessment_label: function () {
      return (this.editable) ? 'Añadir valoración del acuerdo' : 'Valoración del acuerdo (no editable)'
    }
  },

  methods: {
    ...mapActions('agreementHistory', ['getHistory']),
    ...mapActions('agreements', ['updateCurrentItem', 'patchItem']),
    submitAssessment () {
      this.assessment_icon = 'fas fa-circle-notch fa-spin'
      this.assessment_color = 'primary'
      this.onMonitorRequest(apiClient.patch('/old-agreements/' + this.currentItem.id, { 'assessment': this.assessmentInput }))
        .then(response => {
          if (!this.isPendingRequests) {
            this.assessment_icon = 'fa fa-check'
            this.assessment_color = 'success'
            this.getHistory(this.currentItem.id)
          }
          // avoids agreement-detail API call that returns exactly the same object
          this.$store.commit('old-agreements/setCurrentItem', response.data)
        })
        .catch(() => {
          this.assessment_icon = 'fa fa-times'
          this.assessment_color = 'error'
        })
    }
  }
}
</script>

<style>
.assessment-input textarea::-webkit-scrollbar {
  width: 0.5rem;
}

.assessment-input textarea::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}
</style>
