<template>
  <Layout :top-bar-title="getAgreementName" :sections="sections" :displayAllScreen="true"
          :isElementNotFound="isAgreementNotFound" :notFoundProps="notFoundProps">
    <template slot="actionBar">
      <v-dialog :persistent="!this.isAgreementNameValid(currentItem.name)"
                v-if="editableValues" v-model="dialogName" max-width="600px" @click:outside="closeChangeNameDialog">
        <template v-slot:activator="{ on }">
          <v-btn icon dense class="ml-2" color="" v-on="on" :title="$t('agreements.changeAgreementName')"
                 v-show="isCurrentItemFetched" :loading="isNameChanging">
            <v-icon>fa-edit</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('agreements.changeAgreementName') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-on:keyup.enter="closeChangeNameDialog"
                    :rules="[rules.validAgreementName]"
                    v-model="nameInternal"
                    :label="$t('agreements.agreementName')"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-spacer/>
      <v-spacer/>
      <v-row align="center" justify="space-around">
        <v-btn v-show="showApprovedAndExpiredBtn" dark color="success"
               class="elevation-0 align-self-center" :loading="isValidationBtnLoading" v-on:click="approveListFromPendingReview">
          {{ $t('agreements.toApprove') }}
        </v-btn>
        <v-btn v-show="showApprovedAndExpiredBtn" dark color="error"
               class="elevation-0 align-self-center" :loading="isValidationBtnLoading" v-on:click="expiredAgreementFromPendingReview">
          {{ $t('agreements.toReject') }}
        </v-btn>
        <v-btn v-show="currentItem.prev_status !== null && statusInternal.status_key === 'Submitted'" dark color="error"
               class="elevation-0 align-self-center" v-on:click="cancelValidation" :loading="isValidationBtnLoading">
          {{ $t('dialogs.cancelValidation') }}
        </v-btn>
        <v-btn v-show="currentItem.prev_status === null && statusInternal.status_key === 'Draft'" dark color="primary"
               class="elevation-0 align-self-center" v-on:click="submitForValidation" :loading="isValidationBtnLoading">
          <v-icon>mdi-plus</v-icon>
          {{ $t('general.send') }}
        </v-btn>
      </v-row>
      <v-spacer/>
    </template>

    <v-container fluid>

      <ValidationAlert :text="textAlert" v-model="AgreementNotValid" v-if="!isValidationBtnLoading" />

      <v-row>
        <v-col cols="12" lg="12">
          <TotalList class="px-0 py-0"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <AgreementStatus class="py-2" v-model="statusInternal"/>
        </v-col>
      </v-row>

      <!-- Informacion del Acuerdo -->

      <v-card class="pa-5 mb-5">
        <v-row>
          <v-col v-for="(itemPair, index) in readOnlyItems" :key="index" cols="12" lg="4">
            <template v-if="isCurrentItemFetched">
              <v-text-field
                v-for="item in itemPair" :key="item.label"
                readonly filled rounded
                class="input-deadpan"
                :label="item.label"
                :value="item.value"
              />
            </template>
            <template v-else>
              <v-skeleton-loader v-for="item in itemPair" :key="item.label" type="list-item-two-line" />
            </template>
          </v-col>
        </v-row>
      </v-card>

      <template v-if="!isCurrentItemFetched">
        <v-card class="pa-5 mb-5">
          <v-skeleton-loader type="card" :height="'100%'" :width="'100%'" class="my-5" />
        </v-card>
        <v-card class="pa-5 mb-5">
          <v-skeleton-loader type="table" :height="'100%'" :width="'100%'" class="my-5" />
        </v-card>
      </template>

      <!-- Datos generales -->

      <v-card class="pa-5 mb-5" v-if="isCurrentItemFetched">
        <v-dialog v-model="creditorDialog" max-width="800px">
          <CreditorForm
            v-if="creditorDialog"
            v-on:onClose="creditorDialog=false"
            v-on:onCreditorCreated="onCreditorCreated"
            v-on:onError="handleCreditorFormError"
          />
        </v-dialog>
        <v-card-title class="d-flex justify-space-between">
          <span class="headline">{{ $t('general.generalData') }}</span>
          <v-icon
            v-if="isPendingRequests"
            style="font-size: 24px !important"
            :color="'primary'"
          >
            fa-circle-notch fa-spin
          </v-icon>
        </v-card-title>
        <v-row class="my-2">
          <v-col cols="6" class="px-2">
            <v-row no-gutters>
              <v-col>
                <v-autocomplete
                  v-if="editableValues"
                  v-model="currentItem.rate"
                  class="pa-2"
                  :items="validRates"
                  item-value="external_id"
                  item-text="name"
                  :label="$t('general.rate')"
                  required
                  prepend-icon="fa-map-marker-alt"
                  return-object
                  @change="this.updateRate"/>
                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="currentItem.rate.name || 'Sin tarifa asociada'"
                  :label="$t('general.rate')"
                  prepend-icon="fa-calendar-check"
                  readonly/>
              </v-col>
            </v-row>
            <v-row no-gutters class="pb-4">
              <v-col cols="11">
                <v-autocomplete
                  class="pa-2"
                  v-model="currentItem.renovated_from"
                  :items="finalStatusList"
                  :search-input.sync="inputSearch"
                  @change="updateRenovatedFrom"
                  cache-items
                  return-object
                  item-value="external_id"
                  item-text="name"
                  prepend-icon="fa-file-contract"
                  :label="$t('agreements.searchClosedOrActiveAgreements')"
                  :disabled="!this.currentItem.is_renewal"
                  v-if="editableValues"
                ></v-autocomplete>
                <label
                  v-if="!editableValues"
                  class="pa-4 d-flex justify-start">
                  <v-icon class="mr-3">
                    fa-file-contract
                  </v-icon>
                  {{ $t('agreements.sectionName') }}:
                  <a
                    v-if="currentItem.renovated_from !== null"
                    class="ml-2"
                    @click="moveToOldAgreement">
                    {{currentItem.renovated_from.name}}
                  </a>
                  <label v-else class="ml-2">
                    -
                  </label>
                </label>
              </v-col>
              <v-col cols="1">
                <v-checkbox
                  v-if="editableValues"
                  v-model="currentItem.is_renewal"
                  @change="renovatedCheck"
                  class="mx-2"/>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="8">
                <v-autocomplete
                  v-if="editableValues"
                  class="pa-2"
                  :cache-items="hasCreditor"
                  v-model="currentItem.creditor"
                  :items="validCreditors"
                  :loading="loadingValidCreditors"
                  :search-input.sync="queryValidCreditors"
                  item-value="external_id"
                  :item-text="creditorText"
                  :label="$t('general.creditor')"
                  required
                  prepend-icon="fa-receipt"
                  return-object
                  :disabled="!!currentItem.creditor"
                  @click="checkCreditor"
                  @change="this.updateCreditor"/>
                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="creditor"
                  :disabled="hasCreditor"
                  :label="$t('general.creditor')"
                  prepend-icon="fa-calendar-check"
                  readonly
                />
              </v-col>
              <v-col cols="4">
                <v-btn fab small v-if="editableValues && !currentItem.creditor" @click="creditorDialog = true" dark class="primary">
                  <v-icon dark>fa-plus</v-icon>
                </v-btn>
                <v-btn fab small v-if="editableValues && currentItem.creditor" @click="creditorDialog = true" dark class="primary">
                  <v-icon dark>fa-edit</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <p class="text-center">{{ $t('agreements.expirationAgreementBaseRappelLabel') }}</p>

                <DateRangePickerInField
                  v-if="editableValues"
                  :label="$t('agreements.expirationAgreementBaseRappel')"
                  :start.sync="internalExpirationBaseRappelPercentageStart"
                  :end.sync="internalExpirationBaseRappelPercentageEnd"
                  @save="save"/>

                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="currentItem.expiration_agreement_base_rappel_start || $t('general.noAssociatedStartDate')"
                  :label="$t('agreements.expirationAgreementBaseRappelStart')"
                  prepend-icon="fa-calendar-check"
                  readonly
                />

                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="currentItem.expiration_agreement_base_rappel_end || $t('general.noAssociatedEndDate')"
                  :label="$t('agreements.expirationAgreementBaseRappelEnd')"
                  prepend-icon="fa-calendar-check"
                  readonly
                />

                <DateRangePickerInField
                  v-if="editableValues"
                  :label="$t('agreements.expirationAgreementEurBoxBaseRappel')"
                  :start.sync="internalExpirationBaseRappelEurBoxStart"
                  :end.sync="internalExpirationBaseRappelEurBoxEnd"
                  @save="save"/>

                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="currentItem.expiration_agreement_eur_box_base_rappel_start || $t('general.noAssociatedStartDate')"
                  :label="$t('agreements.expirationAgreementEurBoxBaseRappelStart')"
                  prepend-icon="fa-calendar-check"
                  readonly/>

                <v-text-field
                  v-if="!editableValues"
                  class="pa-2"
                  :value="currentItem.expiration_agreement_eur_box_base_rappel_end || $t('general.noAssociatedEndDate')"
                  :label="$t('agreements.expirationAgreementEurBoxBaseRappelEnd')"
                  prepend-icon="fa-calendar-check"
                  readonly/>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="py-0 px-2">
            <DateRangePickerInField
              v-if="editableValues"
              :label="$t('agreements.validityDatesLabel')"
              :start.sync="startInternal"
              :end.sync="endInternal"
              @save="save"/>

            <v-text-field
              v-if="!editableValues"
              class="pa-2"
              :value="currentItem.start_date || $t('general.noAssociatedStartDate')"
              :label="$t('agreements.startDate')"
              prepend-icon="fa-calendar-check"
              readonly
            />

            <v-text-field
              v-if="!editableValues"
              class="pa-2"
              :value="currentItem.end_date || $t('general.noAssociatedEndDate')"
              :label="$t('agreements.endDate')"
              prepend-icon="fa-calendar-check"
              readonly
            />

            <Managements />

            <Beneficiary
              use-prepend-icon
              :agreement__external_id="currentItem.external_id"
              :client="beneficiaryClient"
              :editableValues="can_edit_beneficiary"
              v-if="currentItem.distributor.level !== 1 &&
              currentItem.distributor.level !== 2 &&
              currentItem.distributor.level !== 3"
            />

            <v-select
              v-if="editableValues"
              v-model="settlementTypesBaseRappel"
              :items="validSettlementTypesBaseRappel.map(el => settlementTypeFromEnglish2Spanish(el))"
              :label="$t('agreements.settlementTypeBaseRappel')"
              prepend-icon="fa-calendar-day"
              return-object
              @change="this.updateSettlementType"
            />
            <v-text-field
              v-if="!editableValues"
              class="pa-2"
              :value="settlementTypesBaseRappel"
              :label="$t('agreements.settlementTypeBaseRappel')"
              prepend-icon="fa-calendar-check"
              readonly
            />
            <v-select
              v-if="editableValues"
              v-model="settlementTypesEurBoxRappel"
              :items="validSettlementTypesEurBoxRappel.map(el => settlementTypeFromEnglish2Spanish(el))"
              :label="$t('agreements.settlementTypeEurBoxRappel')"
              prepend-icon="fa-calendar-day"
              return-object
              @change="this.updateSettlementType"
            />
            <v-text-field
              v-if="!editableValues"
              class="pa-2"
              :value="settlementTypesEurBoxRappel"
              :label="$t('agreements.settlementTypeEurBoxRappel')"
              prepend-icon="fa-calendar-check"
              readonly
            />

            <v-select
              v-if="editableValues"
              v-model="agreementPlanType"
              :items="validAgreementPlanTypes.map(el => agreementPlanTypeDict(el))"
              :label="$t('agreements.agreementType')"
              prepend-icon="fa-list"
              return-object
              @change="this.updateAgreementPlanType"
            />
            <v-text-field
              v-if="!editableValues"
              class="pa-2"
              :value="agreementPlanType"
              :label="$t('agreements.agreementType')"
              prepend-icon="fa-list"
              readonly
            />
          </v-col>
        </v-row>
      </v-card>

      <!-- Distributor List -->

      <v-row v-if="isCurrentItemFetched">
        <v-col>
          <DistributorList @save="onDistributorChanged"
                           :editable-beneficiary="can_edit_beneficiary"
                           style="height: fit-content; max-height: 400px ;overflow-y: scroll"
                           :editableValues="editableValues"/>
        </v-col>
      </v-row>

      <v-divider class="ma-5"/>

      <!-- Esto son las pestañas de las tablas de detalles (Plantillas, Aportaciones, Garantias, etc.), abajo buscar en data el diccionario detailsTabs -->

      <v-tabs v-if="isCurrentItemFetched" v-model="detailsTab" grow icons-and-text>
        <v-tab
          v-for="item in detailsTabs"
          :key="item.title"
          icon-and-text
          :disabled="item.disabled"
        >
          {{ item.title }}
          <v-chip @click="() => true" outlined small light> {{getTotalByKey(item.totalKey)}}</v-chip>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="detailsTab">
        <v-tab-item
          v-for="item in detailsTabs"
          :key="item.title"
          eager
        >
          <v-card color="trasparent" class="elevation-5 pa-3">
            <component
              :is="item.component"
              v-on="item.events"
              v-if="currentItem.external_id === agreementId"
              parentKey="agreement__external_id" :parentValue="agreementId" :editableValues="editableValues"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider class="ma-5"/>

      <!-- Pestañas de Valoración, Histórico -->

      <v-tabs v-if="isCurrentItemFetched" v-model="othersTab" grow>
        <v-tab v-for="item in othersTabs" :key="item.title">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="othersTab">
        <v-tab-item v-for="item in othersTabs" :key="item.title" eager>
          <v-card color="trasparent" class="elevation-5 pa-3">
            <component
              :is="item.component"
              v-if="currentItem.external_id === agreementId"
              :editable="editableValues"
              @change="getItem(currentItem.external_id)"
              :parentValue="currentItem.id"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-container>
  </Layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import debounce from 'lodash/debounce'
import { isNull, isString, isUndefined } from 'lodash'

import { labelsMixin } from '@/apps/core/mixins/cosmos_labels'
import Layout from '@/apps/core/components/Layout'
import PrettyAlert from '@/lib/unlogin/components/PrettyAlert'
import ValidationAlert from '@/lib/unlogin/components/ValidationAlert'
import { filtersMixin } from '@/lib/uncrudtable/mixins/filters'
import { requestsMonitorsMixin } from '@/apps/core/mixins/requests_monitors'
import { formatDate, formatDateAndTime, detectAndFormatDate } from '@/apps/core/helpers/utils'
import {
  createErrorNotification,
  createSuccessNotification,
  createWarningNotification
} from '@/lib/unnotificationsqueue'

// form components
import CreditorForm from '../components/forms/CreditorForm'

// field components
import DatePickerInField from '@/apps/core/components/forms/DatePickerInField'
import DateRangePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField.vue'
import Managements from '../components/Managements'
import Beneficiary from '../components/Beneficiary'

// other components
import AgreementStatus from '../components/AgreementStatus'
import TotalList from '../components/TotalList'
import DistributorList from '../components/DistributorList'

// table components
import RappelTable from '../components/tables/RappelTable'
import ContributionTable from '../components/tables/ContributionTable'
import EndorsementTable from '../components/tables/EndorsementTable'
import MerchandisingTable from '../components/tables/MerchandisingTable'
import AgreementLineTable from '../components/tables/AgreementLineTable'

import sections from './navigationDrawerSections'

import Assessment from '../components/Assessment'
import AgreementHistory from '@/apps/juno/components/AgreementHistory/AgreementHistory.vue'
import AgreementDocs from '../components/tables/AgreementDocs'

export default {
  name: 'AgreementDetail',
  components: {
    Managements,
    DateRangePickerInField,
    PrettyAlert,
    ValidationAlert,
    DatePickerInField,
    Layout,
    AgreementStatus,
    DistributorList,
    CreditorForm,
    TotalList,
    Beneficiary
  },
  mixins: [filtersMixin, labelsMixin, requestsMonitorsMixin],

  created () {
    this.clearCurrentItem()
    this.clearAgreementLines()
    this.clearCreditorParams()
    this.initialize()
    this.checkCreditor()
  },

  data () {
    return {
      isAgreementNotFound: false,
      agreementDoesntExistProps: {
        title: 'Acuerdo no encontrado',
        subtitle: 'No se ha encontrado ningún acuerdo con el identificador especificado',
        goToLabel: 'Ir al listado de acuerdos',
        goToIcon: 'fa-clipboard-list',
        redirectTo: { name: 'AgreementList' }
      },
      requestFailedProps: {
        title: 'La petición ha fallado',
        subtitle: 'Por favor, inténtelo de nuevo más tarde',
        goToLabel: 'Ir al listado de acuerdos',
        goToIcon: 'fa-clipboard-list',
        redirectTo: { name: 'AgreementList' }
      },
      notFoundProps: null,
      hasCreditor: true,
      inputSearch: '',
      renovated_type_agreements: [],
      loadingFinalStatusAgreement: false,
      final_state_agreement: null,
      is_renewal_loaded: false,
      autoCompleteCreditor: '',
      creditorDialog: false,
      agreementId: this.$route.params.id,
      sections: sections,
      dialogName: null,
      nameBeforeDialogOpen: '',
      isNameChanging: false,
      isValidationBtnLoading: false,
      detailsTab: null,
      detailsTabs: [
        {
          title: this.$tc('agreements.agreementLine', 2),
          component: AgreementLineTable,
          totalKey: 'agreementlines.extra_fields.total_net_discount',
          events: {
            updateagreement: this.initialize
          }
        }, // Agreement lines , products and numbers
        {
          title: this.$tc('general.contribution', 2),
          component: ContributionTable,
          totalKey: 'contributions.extra_fields.total_contributions',
          events: {
            updateagreement: this.initialize
          }
        }, // Payments / contributors
        {
          title: this.$tc('general.endorsement', 2),
          component: EndorsementTable,
          totalKey: 'endorsements.extra_fields.total_covered',
          events: {}
        }, // Endorserments
        {
          title: this.$tc('general.rappel', 2),
          component: RappelTable,
          totalKey: 'agreementlines.extra_fields.total_per_line_rappel_discount_sell',
          events: {},
          disabled: true
        }, // Rappels from Rea
        {
          title: this.$tc('general.merchandising', 2),
          component: MerchandisingTable,
          events: {},
          disabled: true
        } // Merchandising
      ],
      othersTab: null,
      othersTabs: [
        { title: this.$t('agreements.assessment'), component: Assessment },
        { title: this.$t('general.changelog'), component: AgreementHistory },
        { title: 'Documentación', component: AgreementDocs }
      ],
      menuDatePicker: false,
      loading: true,
      queryValidCreditors: '',
      creditorData: {},
      agreementBeneficiaries: null,
      loadingValidCreditors: false,
      showAlert: false,
      AgreementNotValid: false,
      textAlert: '',
      editingErrors: {},
      rules: {
        validAgreementName: v => this.isAgreementNameValid(v) ||
            'El nombre del acuerdo tiene que tener caracteres alfanuméricos'
      },
      validAgreementPlanTypes: [
        '',
        'alpha',
        'delta',
        'digital_services',
        'wholesalers'
      ],
      agreementProps: {},
      showApprovedAndExpiredBtn: false
    }
  },
  watch: {
    queryValidCreditors (val) {
      // Items have already been requested or query is empty
      if (this.queryValidCreditors !== this.creditor || this.queryValidCreditors === '') {
        this.hasCreditor = false
      }

      if (this.loadingValidCreditors === false && (val === null || val === '')) {

      } else {
        this.fetchValidCreditors()
      }
    },
    inputSearch (val) {
      if (isString(val) && val.length > 0) {
        this.queryAgreementsWithStatusActive(val)
      }
    },
    validBeneficiaries: function (nVal, oVal) {
      if (this.validBeneficiaries.length === 1) {
        this.agreementBeneficiaries = this.validBeneficiaries[0]
      }
    },
    creditor: function (nVal, oVal) {
      this.checkCreditor()
    },
    dialogName (value) {
      if (value) {
        this.nameBeforeDialogOpen = this.nameInternal
      }
    }
  },
  computed: {
    ...mapState('agreements', [
      'currentItem',
      'validRates',
      'validationErrors',
      'validSettlementTypesEurBoxRappel',
      'validSettlementTypesBaseRappel',
      'finalStatusList',
      'clientGroup',
      'messageData'
    ]),
    ...mapState('distributors', [
      'validCreditors',
      'creditor'
    ]),
    ...mapGetters('agreements', ['isCurrentItemFetched']),
    beneficiaryClient: function () {
      return {
        'beneficiary': this.agreementBeneficiaries,
        'external_id': this.currentItem.distributor.external_id
      }
    },
    can_edit_beneficiary () {
      let flag = this.currentItem.agreement_beneficiary_flag
      if (isNull(flag) || isUndefined(flag)) {
        flag = this.editableValues()
      }
      return flag
    },
    getAgreementName: function () {
      return this.isAgreementNotFound ? 'Acuerdo no encontrado'
        : this.isCurrentItemFetched ? `Acuerdo: ${this.currentItem.name} (${this.currentItem.id})`
          : 'Cargando acuerdo...'
    },
    editableValues: function () {
      return this.statusInternal.status_value === 0
    },
    getTotalByKey: function () {
      return key => key ? '€ ' + this.$options.filters.unNumberFmt(
        Lget(this.$store.state, key, '-')) : 'No disponible'
    },
    readOnlyItems: function () {
      return [
        [
          {
            label: 'Creado por',
            value: this.currentItem.created_by ? this.currentItem.created_by.user.username : '-'
          },
          {
            label: 'Fecha de creación',
            value: detectAndFormatDate(this.currentItem.creation_datetime)
          }
        ],
        [
          {
            label: this.$t('general.lastModifiedBy'),
            value: this.currentItem.last_modified_by ? this.currentItem.last_modified_by.user.username : '-'
          },
          {
            label: 'Fecha de última modificación',
            value: detectAndFormatDate(this.currentItem.modification_datetime)
          }
        ],
        [
          {
            label: 'Fecha real de inicio del acuerdo',
            value: this.currentItem.real_start_date ? formatDate(this.currentItem.real_start_date) : '-'
          },
          {
            label: 'Fecha real de fin del acuerdo',
            value: this.currentItem.real_end_date ? formatDate(this.currentItem.real_end_date) : '-'
          }
        ]
      ]
    },
    statusInternal: {
      get: function () {
        const internalStatus = this.currentItem.status.internal_status
        const defaultStatus = { status_key: 'Draft', status_value: 0 }
        return internalStatus === undefined ? defaultStatus : internalStatus
      },
      set: function (value) {
      }
    },
    nameInternal: {
      get: function () {
        return this.currentItem.name
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, name: value })
      }
    },
    startInternal: {
      get: function () {
        return this.currentItem.start_date
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, start_date: value, real_start_date: value })
      }
    },
    endInternal: {
      get: function () {
        return this.currentItem.end_date
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, end_date: value, real_end_date: value })
      }
    },
    internalExpirationBaseRappelPercentageStart: {
      get: function () {
        return this.currentItem.expiration_agreement_base_rappel_start
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, expiration_agreement_base_rappel_start: value })
      }
    },
    internalExpirationBaseRappelPercentageEnd: {
      get: function () {
        return this.currentItem.expiration_agreement_base_rappel_end
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, expiration_agreement_base_rappel_end: value })
      }
    },
    internalExpirationBaseRappelEurBoxStart: {
      get: function () {
        return this.currentItem.expiration_agreement_eur_box_base_rappel_start
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, expiration_agreement_eur_box_base_rappel_start: value })
      }
    },
    internalExpirationBaseRappelEurBoxEnd: {
      get: function () {
        return this.currentItem.expiration_agreement_eur_box_base_rappel_end
      },
      set: function (value) {
        this.updateCurrentItem({ ...this.currentItem, expiration_agreement_eur_box_base_rappel_end: value })
      }
    },
    creditor: {
      get: function () {
        let creditorSelected = 'Sin acreedor asociado'
        return !isUndefined(this.currentItem.creditor) && !isNull(this.currentItem.creditor)
          ? this.currentItem.creditor.id + ' - ' + this.currentItem.creditor.name
          : creditorSelected
      },
      set: function () {
        // empty
      }
    },
    rate: {
      get: function () {
        const defaultText = 'Sin rate asociado'
        const isValidRate = !(isNull(this.currentItem.rate) || isUndefined(this.currentItem.rate))
        return isValidRate ? (this.currentItem.rate.name || defaultText) : defaultText
      },
      set: function (value) {
        // empty
      }
    },
    settlementTypesBaseRappel: {
      get: function () {
        const defaultText = 'Sin periodo de liquidación base asociado'
        const isValidSettlementTypeBaseRappel = !(
          isNull(this.currentItem.settlement_type_base_rappel) ||
            isUndefined(this.currentItem.settlement_type_base_rappel))
        return isValidSettlementTypeBaseRappel ? (
          this.settlementTypeFromEnglish2Spanish(this.currentItem.settlement_type_base_rappel) ||
            defaultText) : defaultText
      },
      set: function (value) {
        this.updateCurrentItem({
          ...this.currentItem, settlement_type_base_rappel: this.settlementTypeFromSpanish2English(value)
        })
      }
    },
    settlementTypesEurBoxRappel: {
      get: function () {
        const defaultText = 'Sin periodo de liquidación €/caja asociado'
        const isValidSettlementTypeEurBoxRappel = !(
          isNull(this.currentItem.settlement_type_eur_box_rappel) ||
            isUndefined(this.currentItem.settlement_type_eur_box_rappel))
        return isValidSettlementTypeEurBoxRappel ? (
          this.settlementTypeFromEnglish2Spanish(this.currentItem.settlement_type_eur_box_rappel) ||
            defaultText) : defaultText
      },
      set: function (value) {
        this.updateCurrentItem({
          ...this.currentItem, settlement_type_eur_box_rappel: this.settlementTypeFromSpanish2English(value)
        })
      }
    },
    agreementPlanType: {
      get: function () {
        const defaultText = ''
        const isValidAgreementPlanType = !(
          isNull(this.currentItem.props) ||
            isUndefined(this.currentItem.props))
        return isValidAgreementPlanType ? (
          this.agreementPlanTypeDict(this.currentItem.props['sch_agreement_type']) || defaultText) : defaultText
      },
      set: function (value) {
        this.agreementProps = this.currentItem.props
        this.$set(this.agreementProps, 'sch_agreement_type', this.agreementPlanTypeDict(value))
        this.updateCurrentItem({
          ...this.currentItem, props: this.agreementProps
        })
      }
    },
    itemToPost () {
      if (this.currentItem) {
        const status = {
          internal_status: `${Lget(this.currentItem, 'status.internal_status.status_value')}`,
          status_type: `${Lget(this.currentItem, 'status.status_type')}`
        }
        const renovatedFrom =
            isNull(this.currentItem.renovated_from) ||
            isUndefined(this.currentItem.renovated_from)
              ? undefined : this.currentItem.renovated_from.external_id
        const creditor =
            isNull(this.currentItem.creditor) ||
            isUndefined(this.currentItem.creditor)
              ? undefined : this.currentItem.creditor.external_id
        return {
          // Here is all the data to post to the backend
          ...this.currentItem,
          agreement_beneficiary: null,
          renovated_from: this.currentItem.is_renewal ? renovatedFrom : undefined,
          created_from: 1,
          agreement_type: this.currentItem.agreement_type.external_id,
          distributor: this.currentItem.distributor.external_id,
          creditor: creditor,
          settlement_type_base_rappel: this.currentItem.settlement_type_base_rappel,
          settlement_type_eur_box_rappel: this.currentItem.settlement_type_eur_box_rappel,
          status: status,
          rate: this.currentItem.rate ? this.currentItem.rate.external_id : '',
          created_by: this.currentItem.created_by ? this.currentItem.created_by.user.username : '',
          last_modified_by: localStorage.getItem('user'),
          expiration_agreement_base_rappel_start: this.internalExpirationBaseRappelPercentageStart,
          expiration_agreement_base_rappel_end: this.internalExpirationBaseRappelPercentageEnd,
          expiration_agreement_eur_box_base_rappel_start: this.internalExpirationBaseRappelEurBoxStart,
          expiration_agreement_eur_box_base_rappel_end: this.internalExpirationBaseRappelEurBoxEnd,
          modification_datetime: new Date(),
          real_start_date: this.currentItem.start_date,
          real_end_date: this.currentItem.end_date
        }
      }
      return null
    }
  },
  methods: {
    ...mapActions('agreements', [
      'getItem',
      'postItem',
      'updateCurrentItem',
      'clearCurrentItem',
      'getValidRates',
      'getValidSettlementTypesEurBoxRappel',
      'getValidSettlementTypesBaseRappel',
      'getValidationErrors',
      'getFinalStatusItemList',
      'getClientGroup',
      'setPaymentTypeCondition',
      'setResetCurrentItem'
    ]),
    ...mapActions('distributors', {
      updateCurrentDistributor: 'updateCurrentItem',
      getValidCreditors: 'getValidCreditors',
      clearCreditorParams: 'clearCreditorParams',
      getDistributorParams: 'getDistributorParams'
    }),
    ...mapActions('agreementlines', { clearAgreementLines: 'clearItemList' }),
    ...mapActions('agreementHistory', ['getHistory']),
    ...mapActions('contacts', { getContacts: 'getItemList', getManagers: 'getManagers' }),
    ...mapActions({ addNotification: 'addNotification' }),

    /** External functions */
    formatDateAndTime,
    formatDate,
    Lget: Lget,

    /**
       * Function to add valid creditors to vuex store.
       * */
    addValidCreditors () {
      let creditors = []
      if (!isNull(this.currentItem.creditor) && !isUndefined(this.currentItem.creditor)) {
        creditors.push(this.currentItem.creditor)
      }
      // updating creditors state
      this.$store.commit(
        'distributors/setValidCreditors', { creditors: creditors })
    },
    /**
       * Function to add valid rates to vuex store.
       * */
    addValidRates () {
      let rates = []
      if (!isNull(this.currentItem.rate) && !isUndefined(this.currentItem.rate)) {
        rates.push(this.currentItem.rate)
      }
      this.$store.commit(
        'agreements/setValidRates', { rates: rates })
    },
    initialize () {
      // TODO chain all the neccesary nested models here
      this.getItem(this.agreementId)
        .then(() => {
          if (!this.currentItem.external_id) {
            this.notFoundProps = this.agreementDoesntExistProps
            this.isAgreementNotFound = true
          } else {
            this.isValidationBtnLoading = false
            this.getContacts({ distributor__external_id: this.currentItem.distributor.external_id })
            this.addValidCreditors()
            // this.addValidRates() // TODO: this function it is not working at pre2
            if (this.currentItem.renovated_from !== null && this.currentItem.renovated_from !== undefined &&
              this.currentItem.renovated_from.status.status_value === 6) {
              this.$store.commit(`agreements/setFinalStatusItemList`, [this.currentItem.renovated_from])
            } else {
              this.getFinalStatusItemList()
            }

            if (this.currentItem.distributor.level !== 1 &&
              this.currentItem.distributor.level !== 2 &&
              this.currentItem.distributor.level !== 3) {
              if (this.currentItem.agreement_beneficiary && this.currentItem.agreement_beneficiary.length > 0) {
                this.agreementBeneficiaries = this.currentItem.agreement_beneficiary[0]
              }
            }
            this.showApprovedAndExpiredBtn = this.currentItem.show_approve_and_expire_buttons
          }
        })
        .catch(() => {
          this.notFoundProps = this.requestFailedProps
          this.isAgreementNotFound = true
        })
        // this.getManagers()
      this.getValidRates()
      this.getValidSettlementTypesEurBoxRappel()
      this.getValidSettlementTypesBaseRappel()
      this.loading = false
    },
    queryAgreementsWithStatusActive: debounce(function (input) {
      this.loadingFinalStatusAgreement = true
      this.getFinalStatusItemList({
        search: input
      })
      this.loadingFinalStatusAgreement = false
    }, 500),
    ShowAgreementNotValid (text) {
      this.textAlert = text
      this.AgreementNotValid = true
    },
    ValidAgreement () {
      this.AgreementNotValid = false
    },
    isAgreementNameValid (agreementName) {
      if (agreementName === null || agreementName === undefined) {
        return false
      }
      if (!(/^[\s]+$/.test(agreementName) || agreementName.length < 1)) {
        return true
      } else {
        return false
      }
    },
    async closeChangeNameDialog () {
      if (this.nameInternal !== this.nameBeforeDialogOpen && this.isAgreementNameValid(this.currentItem.name)) {
        try {
          this.nameBeforeDialogOpen = this.nameInternal
          this.isNameChanging = true
          this.dialogName = false
          await this.save()
        } finally {
          this.isNameChanging = false
        }
      }
    },
    save () {
      this.validateDates()
      return this.onMonitorRequest(this.postItem(this.itemToPost))
        .then(() => {
          this.addNotification(createSuccessNotification('Acuerdo modificado correctamente'))
          if (!this.isPendingRequests) {
            this.getItem(this.currentItem.external_id)
            this.getHistory({ id: this.currentItem.id })
          }
          return Promise.resolve()
        })
        .catch((error) => {
          this.addNotification(createErrorNotification('Ha ocurrido un error al guardar el acuerdo'))
          return Promise.reject(error)
        })
        .finally(() => {
          console.log('save finally')
        })
    },
    validateDates () {
      if (this.internalExpirationBaseRappelPercentageEnd === '') {
        this.internalExpirationBaseRappelPercentageEnd = null
        this.addNotification(createWarningNotification('Debe agregar una fecha fin para "Rappel base (%)"'))
      }
      if (this.internalExpirationBaseRappelEurBoxEnd === '') {
        this.internalExpirationBaseRappelEurBoxEnd = null
        this.addNotification(createWarningNotification('Debe agregar una fecha fin para "Rappel SPM (€/caja)"'))
      }
      if (this.endInternal === '') {
        this.endInternal = null
        this.addNotification(createWarningNotification('Debe agregar una fecha fin para "Validez del acuerdo"'))
      }
    },
    submitForValidation () {
      this.isValidationBtnLoading = true
      this.validateDates()
      const itemToPost = this.itemToPost
      itemToPost.status = {
        internal_status: `${Lget(this.currentItem, 'next_state.internal_status.status_value')}`,
        status_type: `${Lget(this.currentItem, 'next_state.status_type')}`
      }
      this.postItem(itemToPost)
        .then(() => {
          this.initialize()
          this.ValidAgreement()
          this.getHistory({ id: this.currentItem.id })
          this.addNotification(createSuccessNotification('Acuerdo enviado correctamente'))
        })
        .catch(err => {
          this.isValidationBtnLoading = false
          const errorCode = err.response.status
          if (errorCode === 403) {
            let validationErrors = err.response.data['validation_errors']
            const errorCount = validationErrors['error_count']
            if (errorCount > 0) {
              validationErrors = validationErrors[itemToPost['external_id']]
              delete validationErrors['error_count']
              const errorMessages = []
              for (const i in validationErrors) {
                errorMessages.push(validationErrors[i]['error_msg'])
              }
              this.ShowAgreementNotValid('Acuerdo no válido: \n' + errorMessages.join(' \n'))
            }
          }
        })
    },
    cancelValidation () {
      this.isValidationBtnLoading = true
      const itemToPost = this.itemToPost
      itemToPost.status = {
        internal_status: `${Lget(this.currentItem, 'prev_status.internal_status.status_value')}`,
        status_type: `${Lget(this.currentItem, 'prev_status.status_type')}`
      }
      itemToPost.cancel_validation = true
      this.postItem(itemToPost)
        .then(() => {
          this.initialize()
          this.getHistory({ id: this.currentItem.id })
          this.addNotification(createSuccessNotification('Validación cancelada correctamente'))
        })
        .catch(err => {
          console.log(err)
          this.addNotification(createErrorNotification('No se ha podido cancelar el acuerdo'))
          this.isValidationBtnLoading = false
        })
    },
    /**
       * Function called when a creditor is created and agreement detail view
       * need to be updated with that event.
       * @param {object} nCreditor creditor
       * */
    async onCreditorCreated (nCreditor) {
      this.loadingValidCreditors = true
      this.creditorDialog = false
      this.validateDates()
      let itemToPost = { ...this.itemToPost, creditor: nCreditor.external_id }
      try {
        // updating agreement's creditor
        const rPost = await this.postItem(itemToPost)
        this.getHistory({ id: this.currentItem.id })
        console.log('updating agreement creditor')
        console.log(rPost)
        // updating creditors state
        await this.$store.commit(
          'distributors/setValidCreditors', { creditors: [nCreditor] })
        this.addNotification(createSuccessNotification('El acreedor se ha creado correctamente'))
      } catch (e) {
        this.addNotification(createErrorNotification('Error al actualizar el acreedor del acuerdo'))
        console.error(e)
      }
      this.currentItem.creditor = nCreditor
      this.loadingValidCreditors = false
    },
    async updateRate () {
      try {
        this.$store.commit('agreementlines/setLoading', true)
        await this.save()
        await this.$store.dispatch('agreementlines/reloadItemList')
      } catch {
        this.$store.commit('agreementlines/setLoading', false)
      }
    },
    updateSettlementType () {
      this.save()
    },
    updateAgreementPlanType () {
      this.save()
    },
    fetchValidCreditors: debounce(async function () {
      this.queryValidCreditors = this.queryValidCreditors.trim()
      if (this.queryValidCreditors.length > 0) {
        this.loadingValidCreditors = true
        let searchQuery = this.queryValidCreditors.split(' - ')
        searchQuery = searchQuery.length > 1 ? searchQuery[searchQuery.length - 1] : searchQuery[0]
        await this.getValidCreditors({ search: searchQuery, limit: 999, level: -4 })
        this.loadingValidCreditors = false
      }
    }, 500),

    async updateCreditor () {
      this.save()
    },
    textToShow: item => item.external_id + ' - ' +
        (!(isNull(item.name) || isUndefined(item.name)) ? item.name : '[Sin Nombre]') + ' - ' +
        (!(isNull(item.center) || isUndefined(item.center)) ? item.center.name : '[Sin Centro]') + ' - ' +
        (!(isNull(item.props) || isUndefined(item.props)) ? (!(isNull(item.props.route) || isUndefined(item.props.route))
          ? ((item.props.route === '') ? '[Sin Ruta]' : item.props.route) : '[Sin Ruta]') : '[Sin Ruta]'),
    async onDistributorChanged () {
      this.addNotification(createSuccessNotification('Se ha actualizado el cliente correctamente'))
      try {
        this.validateDates()
        await this.postItem(this.itemToPost)
        await this.setPaymentTypeCondition(this.currentItem.external_id)
        this.getHistory({ id: this.currentItem.id })
        await this.getItem(this.currentItem.external_id)

        if (this.currentItem.distributor.level !== 1 &&
          this.currentItem.distributor.level !== 2 &&
          this.currentItem.distributor.level !== 3) {
          if (this.currentItem.agreement_beneficiary.length > 0) {
            this.agreementBeneficiaries = this.currentItem.agreement_beneficiary[0]
          }
        }
      } catch (err) {
        this.addNotification(createErrorNotification('Ha ocurrido un error al guardar el acuerdo'))
        console.error(err)
      }
    },
    async moveToOldAgreement () {
      await this.$router.push({
        name: 'AgreementDetail',
        params: { id: this.currentItem.renovated_from.external_id }
      })
      location.reload()
    },
    async renovatedCheck () {
      /* Pending removal depending on desired functionality
      if (!this.currentItem.is_renewal) {
        this.updateCurrentItem({
          ...this.currentItem,
          renovated_from: undefined,
          start_date: undefined,
          end_date: undefined
        })
      }
      */
      await this.save()
    },
    async updateRenovatedFrom () {
      this.updateCurrentItem({
        ...this.currentItem,
        start_date: this.currentItem.renovated_from.start_date,
        end_date: this.currentItem.renovated_from.end_date,
        real_end_date: this.currentItem.renovated_from.end_date,
        real_start_date: this.currentItem.renovated_from.start_date
      })
      await this.save()
    },
    getCreditorSearchParams (query) {
      let values = query.split(' ')
      let validator = values.indexOf('-') === 1 && !isNaN(values[0])
      let result = {}
      if (validator) {
        let creditorName = query.replace(values[0] + ' - ', '')
        result = { id: parseInt(values[0]), name: creditorName }
      } else {
        // result = isNaN(query) ? { 'creditorName': query } : { 'creditorId': parseInt(query) }
        result = { id: query, external_id: query, name: query }
      }
      return result
    },
    creditorText (item) {
      return item.id + ' - ' + item.name
    },
    checkCreditor () {
      if (!isUndefined(this.creditor) && !isUndefined(this.currentItem.creditor) && !isNull(this.currentItem.creditor)) {
        this.hasCreditor = true
      } else {
        this.hasCreditor = false
      }
    },
    handleCreditorFormError () {
      this.addNotification(createErrorNotification('Ha ocurrido un error al crear el acreedor'))
    },
    async updateBeneficiaryByClientSelected () {
      const client = this.currentItem.distributor
      this.agreementBeneficiaries = null
      if (client.props['tipo_pago'] === 'CO') {
        let params = {
          search: { external_id: client.external_id },
          required: 'partner_distributor'
        }
        await this.getDistributorParams(params).then(res => {
          this.agreementBeneficiaries = res.partner_distributor
        })
        await this.updateBeneficiary()
        this.queryValidBeneficiaries = this.agreementBeneficiaries.external_id + ' - ' + this.agreementBeneficiaries.name
      }

      if (client.props['tipo_pago'] === 'CR') {
        this.agreementBeneficiaries = client
        await this.updateBeneficiary()
        this.queryValidBeneficiaries = this.agreementBeneficiaries.external_id + ' - ' + this.agreementBeneficiaries.name
      }
    },
    async reloadAgreement () {
      await this.clearCurrentItem()
      await this.setResetCurrentItem()
      await this.clearAgreementLines()
      await this.clearCreditorParams()
      await this.initialize()
      await this.checkCreditor()
    },
    async actionApproveAndExpireMessage (action, params) {
      let path = `agreements/${action}ListFromPendingReview`
      await this.$store.dispatch(path, params).then(() => {
        if (this.messageData.data.status === 'success') {
          this.addNotification(createSuccessNotification(this.messageData.data.message))
        }
        if (this.messageData.data.status === 'warning') {
          this.addNotification(createWarningNotification(this.messageData.data.message))
        }
        if (this.messageData.data.status === 'error') {
          this.addNotification(createErrorNotification(this.messageData.data.message))
        }
      })
    },
    async approveListFromPendingReview () {
      this.isValidationBtnLoading = true
      let params = [{
        external_id: this.currentItem.external_id
      }]
      await this.actionApproveAndExpireMessage('approve', params)
      await this.reloadAgreement()
      this.isValidationBtnLoading = false
    },
    async expiredAgreementFromPendingReview () {
      this.isValidationBtnLoading = true
      let params = [{
        external_id: this.currentItem.external_id
      }]
      await this.actionApproveAndExpireMessage('expire', params)
      await this.reloadAgreement()
      this.isValidationBtnLoading = false
    }
  }
}
</script>

<style>
.v-text-field.input-deadpan > .v-input__control > .v-input__slot {
  cursor: default;
  background: rgba(0, 0, 0, 0.06) !important;
}

.v-text-field.input-deadpan > .v-input__control > .v-input__slot input {
  cursor: default;
}

.v-text-field.input-deadpan > .v-input__control > .v-input__slot label {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
