<template>
  <v-container fluid class="d-flex">
    <v-row align="center">
      <v-col class="d-flex" cols="12" sm="4" md="" v-for="total in totals" :key="total.name">
        <KpiCard
          :title="total.name"
          :value="isCurrentItemFetched ? total.value : undefined"
          :icon="total.icon"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { unNumberFmt, unPercentFmt } from '@/lib/uncrudtable/mixins/filters'
import KpiCard from '../../core/components/KpiCard'

export default {
  name: 'TotalList',
  components: { KpiCard },
  computed: {
    ...mapState('agreements', ['extra_fields', 'currentItem']),
    ...mapGetters('agreements', ['isCurrentItemFetched']),
    totals: function () {
      return [
        {
          name: 'Total Condiciones',
          value: unNumberFmt(this.currentItem.amount) + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: 'Total volumen',
          value: unNumberFmt(this.extra_fields.total_volume),
          icon: 'fa-cubes'
        },
        {
          name: 'NSV €',
          value: unNumberFmt(this.extra_fields.total_final_net_sell) + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: 'GCBM €/Litro',
          value: unNumberFmt(this.extra_fields.total_final_net_sell_per_liter) + '€',
          icon: 'fa-euro-sign'
        },
        {
          name: 'Total % de condiciones',
          value: unPercentFmt(this.extra_fields.total_percentage),
          icon: 'fa-percentage'
        }
      ]
    }
  }
}
</script>
