<template>
  <div>
    <!--    showed in all screen sizes less md-->
    <div :value="value" @change="onChangeStatus" class="d-none d-md-block">
      <v-stepper-header>
        <template v-for="(statusKey, index) in finalStatusList">
          <v-btn :key="`btn${statusKey.index_order}`" fab small class="ma-4"
                 :class="{primary: value.status_key===statusKey.internal_status.status_key}">
            <v-icon v-on:click="onChangeStatus(statusKey.index_order)"
                    :key="`i${statusKey.index_order}`"
                    :step="statusKey.index_order">
              {{ orderedStatusesIcons[statusKey.index_order].icon }}
            </v-icon>
          </v-btn>
          <p :key="`p${statusKey.index_order}`" class="mt-5 mr-5">{{statusKey.name}}</p>
          <v-divider :key="statusKey.index_order" class="mx-1" v-if="index < finalStatusListLen - 1" ></v-divider>
        </template>
      </v-stepper-header>
    </div>

    <!--    only showed in md and xs screen sizes-->
    <div class="d-flex d-md-none">
      <v-row align="center">
        <v-col class="d-flex" cols="12">
          <v-select
            :items="finalStatusList.map(el => getStatusLabelByIndex(el.index_order))"
            label="Status"
            dense
            outlined
            v-on:change="onChangeStatus"
          >
          </v-select>
        </v-col>
      </v-row>
    </div>
  </div>

</template>

<script>
import { labelsMixin } from '@/apps/core/mixins/cosmos_labels'
import { find } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AgreementStatuses',
  mixins: [labelsMixin],
  props: {
    value: Object
  },

  data () {
    return {
      validStatusOrder: [0, 1, 7, 2, 3, 5, 4]
    }
  },
  computed: {
    ...mapGetters('status', ['statusList', 'statusListLen']),
    finalStatusList () {
      const obj = find(this.statusList, obj => {
        return obj.internal_status.status_key === this.value.status_key
      })
      try {
        if (obj.internal_status.status_key === 'Rejected') {
          return this.excludeByInternalStatus(status => {
            return status !== 'Active' && status !== 'Approved' && status !== 'Expired' && status !== 'Fake'
          })
        } else if (obj.internal_status.status_key === 'Expired') {
          return this.excludeByInternalStatus(status => {
            return status !== 'Active' && status !== 'Rejected' && status !== 'Fake'
          })
        }
      } catch (e) {
        // catching error when obj variable is null
      }
      return this.excludeDefault()
    },
    finalStatusListLen () {
      return this.finalStatusList.length
    }
  },

  methods: {
    ...mapActions('status', ['getStatusList']),
    onChangeStatus (value) {
      this.$emit('input', value)
    },
    excludeByInternalStatus (func) {
      let tmpList = []
      this.validStatusOrder.forEach(num => {
        if (this.statusList.length > 0) {
          tmpList.push(this.statusList.find(el => {
            return el.index_order === num
          }))
        }
      })
      return tmpList.filter(el => {
        return func === null || func === undefined ? el : func(el.internal_status.status_key)
      })
    },
    excludeDefault () {
      return this.excludeByInternalStatus(status => {
        return status !== 'Rejected' && status !== 'Expired' && status !== 'Fake'
      })
    }
  },
  async beforeMount () {
    await this.getStatusList({
      status_type: 2,
      ordering: 'index_order'
    })
  }
}

</script>

<style scoped>
</style>
