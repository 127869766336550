<template>
  <v-card>
    <v-form @submit.prevent="submit" ref="form">
      <v-card-title>
        <span class="headline">Nueva contribución</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                prepend-icon="fa-tag"
                :items="contributionTypeList"
                item-value="external_id"
                item-text="name"
                label="Seleccione tipo de aportación"
                required :rules="rules.empty"
                v-model="selectedContributionType"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="saveForm" :loading="loading">Guardar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import range from 'lodash/range'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ContributionForm',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      // loading: true,
      selectedContributionType: null,
      numLinesToCreate: 1,
      editingErrors: {},
      rules: {
        empty: [val => this.isEmpty(val)]
      }

    }
  },

  created () {
    this.initialize()
  },

  computed: {
    ...mapState('agreements', {
      currentAgreement: 'currentItem'
    }),
    ...mapState('contributions', {
      categories: 'categories'
    }),
    ...mapState('contributionTypes', {
      contributionTypeList: 'items'
    }),
    itemListToSend () {
      let props = {}
      for (const v of this.categories) {
        if (v.name === 'Todos') {
          props = {
            portfolio: v
          }
        }
      }
      return range(this.numLinesToCreate).map(idx => ({
        type: this.selectedContributionType,
        agreement: this.currentAgreement.external_id,
        created_from: 1,
        props: props
      })
      )
    }
  },

  methods: {
    ...mapActions('contributions', ['postManyItems', 'getValidPortfolios', 'getItemList']),
    ...mapActions('contributionTypes', {
      getContributionTypeList: 'getItemList'
    }),
    initialize () {
      this.getValidPortfolios()
      this.getContributionTypeList().then(() => {
        // this.loading = false
        this.$emit('update:loading', false)
      })
    },
    isEmpty (value) {
      if (!value && value !== 0) {
        return 'Este campo es requerido'
      } else {
        return true
      }
    },
    closeForm () {
      this.$emit('close')
    },
    saveForm () {
      if (this.$refs.form.validate()) {
        this.$emit('update:loading', true)
        this.postManyItems(this.itemListToSend).then(() => {
          this.$emit('save')
          this.$refs.form.reset()
          this.closeForm()
        }).finally(() => {
          this.$emit('update:loading', false)
        })
      }
    }
  },

  updated () {
    this.$refs.form.resetValidation()
  }
}
</script>

<style scoped>

</style>
