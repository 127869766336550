var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CrudTable',{attrs:{"storeModule":_vm.storeModule,"headers":_vm.headers,"filterItems":_vm.filterItems,"customOptions":_vm.customOptions,"getActionPath":_vm.getActionPath,"customActions":_vm.customActions,"parentKey":this.parentKey,"parentValue":this.parentValueString,"editableValues":true,"itemsPerPage":[10, 25, 50, 75]},on:{"messageEventResult":function($event){return _vm.displayNotification($event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var close = ref.close;
return [_c('AgreementDocsForm',{on:{"save":function($event){return _vm.onItemCreated(close)},"close":close}})]}},{key:"item.creation_datetime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("detectAndFormatDate")(item.creation_datetime)))])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"black","dark":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(item.file),expression:"item.file"}],staticClass:"my-3",attrs:{"href":item.file,"color":"primary","target":"_blank","fab":"","small":"","dark":""}},on),[_c('v-icon',[_vm._v("fas fa-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.valueToShow(item.file)))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }