<template>
  <v-card >
    <v-form @submit.prevent="submit" ref="form">
    <v-card-title>
      <span class="headline">Nuevo rappel</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-select
              v-model="level"
              prepend-icon="fa-sitemap"
              :items="validLevelsList"
              item-value="value"
              item-text="text"
              label="Nivel de cliente"
              required :rules="rules.empty"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="darken-1" text @click="closeForm">Cancel</v-btn>
      <v-btn color="darken-1" text @click="saveForm">Save</v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'RappelForm',
  components: {},
  created () {
    this.initialize()
  },
  props: {
  },
  data () {
    return {
      level: '',
      menuDatePicker: false,
      loading: true,
      editingErrors: {},
      rules: {
        empty: [val => this.isEmpty(val)]
      }

    }
  },
  computed: {
    ...mapState('rappels', ['settlementTypes']),
    ...mapState('agreements', {
      currentAgreement: 'currentItem'
    }),
    ...mapState('distributors', ['validLevels']),
    ...mapGetters('distributors', ['validLevelsList']),
    itemToSend () {
      return {
        ...this.editedItem,
        level: this.level,
        created_from: 1,
        agreement: this.currentAgreement.external_id
      }
    }

  },
  methods: {
    ...mapActions('rappels', ['postItem']),
    initialize () {
      this.loading = false
    },

    isEmpty (value) {
      if (!value && value !== 0) {
        return 'Este campo es requerido'
      } else {
        return true
      }
    },

    closeForm () {
      this.$emit('close')
    },
    saveForm (editedItem) {
      if (this.$refs.form.validate()) {
        this.postItem(this.itemToSend)
          .then(() => {
            this.$emit('save')
            this.$refs.form.reset()
            this.closeForm()
          })
      }
    }
  },

  updated () {
    this.$refs.form.resetValidation()
  }
}
</script>

<style scoped>

</style>
