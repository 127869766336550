<template>
  <v-menu
    v-model="openCalendar"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        class="pa-2"
        :value="internalValue"
        v-bind:label="label"
        prepend-icon="fa-calendar-day"
        v-on="on"
        readonly
      />
    </template>
    <v-date-picker no-title light v-bind:value="this.internalValue" @change="onChange"/>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePickerInField',
  props: {
    label: String,
    value: String
  },
  data () {
    return {
      openCalendar: false
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    }
  },
  methods: {
    onChange (value) {
      this.internalValue = value
      this.openCalendar = !this.openCalendar
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
