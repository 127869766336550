<template>
  <v-alert
    v-model="visible"
    dense
    type="error"
  >
    <span v-for="(elem, index) in text.split('\n')" :key="index">
      {{elem}}
      <br>
    </span>

  </v-alert>

</template>

<script>
export default {
  name: 'ValidationAlert',

  props: ['text', 'value'],

  computed: {
    visible: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
