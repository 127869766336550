<template>
  <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
             :customOptions="customOptions" @save="onAgreementLineEdition" :getActionPath="getActionPath"
             :customActions="customActions" :parentKey="this.parentKey" :parentValue="this.parentValue"
             :editableValues="editableValues" v-on:messageEventResult="onActionTriggered($event)" :itemsPerPage="[10, 25, 50, 75]"
             :stickyColumns="2" :hiddenFilters="hiddenFilters" :isFormLoading="isFormLoading"
             :additionConflict="additionConflict"
  >

    <template v-slot:item.props.mix="{item}">
      <span>{{item.props.mix | unPercentFmt}}</span>
    </template>

  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState, mapActions } from 'vuex'
import Lget from 'lodash/get'
import Lset from 'lodash/set'
import { filtersMixin } from '@/lib/uncrudtable/mixins/filters'
import { RESPONSE_LEVEL } from '@/variables'

export default {
  name: 'AgreementLineTable',
  components: { CrudTable },
  mixins: [filtersMixin],
  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'agreementlines',
      filterItems: [],
      isFormLoading: false,
      customOptions: {
        'sortBy': ['rate_product__product__external_id'],
        'sortDesc': [false]
      },
      headers: [
        { text: 'SKU', value: 'rate_product.product.external_id', align: 'end', width: '100px', hide: false },
        { text: 'Nombre', value: 'rate_product.product.name', align: 'start', hide: false, width: '250px' },
        { text: 'Descripción', value: 'rate_product.product.description', align: 'start', width: '250px', hide: false },
        { text: 'Marca', value: 'rate_product.product.brand.name', align: 'end', hide: false },
        { text: 'Clase', value: 'rate_product.product.category_0.name', align: 'end', hide: false },
        { text: 'Sabor', value: 'rate_product.product.category_1.name', align: 'end', hide: false },
        { text: 'Mix', value: 'props.mix', align: 'end', hide: false },
        { text: 'UC', value: 'rate_product.product.props.uc', align: 'end', type: 'integer', hide: false },
        { text: 'Cajas TAM', value: 'boxes_mat', align: 'end', hide: false, type: 'integer', total: ' total_boxes_mat' },
        { text: 'Cajas Propuesta', value: 'boxes', align: 'end', hide: false, editable: true, type: 'integer', total: 'total_boxes' },
        { text: 'Volumen (L)', value: 'calculated.volume', align: 'end', formatNumber: true, hide: false, total: 'total_volume' },
        { text: 'Tarifa (€)', value: 'rate_product.price', align: 'end', formatNumber: true, hide: false },
        { text: 'Venta Bruta (€)', value: 'calculated.gross_sell', align: 'end', formatNumber: true, total: 'total_gross_sell', hide: false },
        { text: 'Descuento fijo (%)', value: 'discount.fix', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_fix_discount', isPercentage: true },
        { text: 'Descuento fijo (€)', value: 'calculated.fix_discount_sell', align: 'end', formatNumber: true, hide: false, total: 'total_fix_discount_sell' },
        { text: 'Descuento promo teórico (%)', value: 'discount.promo', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_promo_discount', isPercentage: true },
        { text: 'Descuento promo teórico (€)', value: 'calculated.promo_discount_sell', align: 'end', formatNumber: true, hide: false, total: 'total_promo_discount_sell' },
        { text: 'Descuento fijo (€ / caja)', value: 'calculated.fix_discount_sell_per_box', align: 'end', formatNumber: true, hide: false, total: 'total_fix_discount_sell_per_box' },
        { text: 'VN factura (€)', value: 'calculated.net_sell', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell' },
        { text: 'VN factura por caja (€ / caja)', value: 'calculated.net_sell_per_box', align: 'end', formatNumber: true, total: 'total_net_sell_per_box', hide: false },
        { text: 'VN factura por UC (€ / UC)', value: 'calculated.net_sell_per_uc', align: 'end', formatNumber: true, total: 'total_net_sell_per_uc', hide: false },
        { text: 'VN factura por litro (€ / litro)', value: 'calculated.net_sell_per_liter', align: 'end', formatNumber: true, total: 'total_net_sell_per_liter', hide: false },
        { text: 'Rappel (%)', value: 'discount.base_rappel', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_base_rappel_discount', isPercentage: true },
        { text: 'Rappel (€)', value: 'calculated.rappel_discount_sell', align: 'end', hide: false, editable: false, type: 'float', formatNumber: true, total: 'total_rappel_discount_sell' },
        { text: 'VN Factura-Rappel (€)', value: 'calculated.net_sell_with_rappel', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell_with_rappel' },
        { text: 'VN Factura-Rappel (€ / litro)', value: 'calculated.net_sell_with_rappel_per_liter', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell_with_rappel_per_liter' },
        { text: 'Rappel SPM (€ / caja)', value: 'discount.eur_box_base_rappel', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_eur_box_base_rappel' },
        { text: 'Rappel SPM (€)', value: 'calculated.eur_base_rappel', align: 'end', hide: false, editable: false, type: 'float', formatNumber: true, total: 'total_eur_base_rappel' },
        { text: 'Rappel Total (€)', value: 'calculated.total_per_line_rappel_discount_sell', align: 'end', formatNumber: true, hide: false, total: 'total_per_line_rappel_discount_sell' },
        { text: 'Aportación ant. (%)', value: 'discount.anticipated', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_anticipated_discount', isPercentage: true },
        { text: 'Aportación ant. (€)', value: 'calculated.anticipated_discount_sell_without_eur_box', align: 'end', formatNumber: true, hide: false, total: 'total_anticipated_discount_sell_without_eur_box' },
        { text: 'Aportación ant. SPM (€ / caja)', value: 'discount.eur_box', align: 'end', hide: false, editable: true, type: 'float', formatNumber: true, total: 'total_eur_box_discount' },
        { text: 'Aportación ant. SPM (€)', value: 'calculated.eur_box_sell', align: 'end', formatNumber: true, hide: false, total: 'total_eur_box_sell' },
        { text: 'Otras aportaciones (€)', value: 'calculated.other_contributions', align: 'end', formatNumber: true, hide: false, total: 'total_other_contributions' },
        { text: 'Descuento anticipado (€)', value: 'calculated.anticipated_discount_sell', align: 'end', formatNumber: true, hide: false, total: 'total_anticipated_discount_sell' },
        { text: 'VN con aportación (€)', value: 'calculated.net_sell_with_anticipated', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell_with_anticipated' },
        { text: 'VN con aportación (€ / caja)', value: 'calculated.net_sell_with_anticipated_per_box', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell_with_anticipated_per_box' },
        { text: 'VN con aportación (€ / UC)', value: 'calculated.net_sell_with_anticipated_per_uc', align: 'end', formatNumber: true, hide: false, total: 'total_net_sell_with_anticipated_per_uc' },
        { text: 'Azucar y PV (€)', value: 'calculated.additional_costs', align: 'end', formatNumber: true, hide: false, total: 'total_additional_costs' },
        { text: 'Margen e incentivos DV (€)', value: 'calculated.additional_incentives', align: 'end', formatNumber: true, hide: false, total: 'total_additional_incentives' },
        { text: 'NSV (€)', value: 'calculated.final_net_sell', align: 'end', formatNumber: true, hide: false, total: 'total_final_net_sell' },
        { text: 'NSV (€ / litro)', value: 'calculated.final_net_sell_per_liter', align: 'end', formatNumber: true, hide: false, total: 'total_avg_net_sell_per_liter' },
        { text: 'Costes (€)', value: 'calculated.costs', align: 'end', formatNumber: true, hide: false, total: 'total_costs' },
        { text: 'GCBM (€)', value: 'calculated.margin', align: 'end', formatNumber: true, hide: false, total: 'total_margin' },
        { text: 'GCBM (€ / litro)', value: 'calculated.margin_per_liter', align: 'end', formatNumber: true, hide: false, total: 'total_margin_per_liter' }
      ]
    }
  },

  computed: {
    ...mapState('agreementlines', ['extra_fields', 'customActions', 'getActionPath']),
    ...mapState('agreements', { currentAgreement: 'currentItem' }),
    hiddenFilters () {
      let filter = {}
      filter[this.parentKey] = this.parentValue
      return filter
    },
    additionConflict () {
      return (!this.currentAgreement.rate) || (this.currentAgreement.rate.fake)
        ? { message: 'Debe seleccionar una tarifa para poder añadir productos.', status: RESPONSE_LEVEL.WARNING }
        : null
    }
  },

  methods: {
    Lget: Lget,
    ...mapActions('contributions', { getContributions: 'reloadItemList' }),
    ...mapActions('agreementlines', ['reloadItemList']),
    ...mapActions({ addNotification: 'addNotification', dismissNotifications: 'dismissNotifications' }),
    onActionTriggered (action) {
      if (action && action.action_name !== 'export_xls') {
        this.reloadItemList()
        this.getContributions({
          agreement__external_id: this.currentAgreement.external_id
        })
        this.$emit('updateagreement')
      }
    },

    onLinesCreated (realoadAll) {
      if (realoadAll) {
        this.reloadItemList()
        this.getContributions({
          agreement__external_id: this.currentAgreement.external_id
        })
        this.$emit('updateagreement')
      } else {
        this.reloadItemList()
      }
    },
    onItemDeleted () {
      this.reloadItemList()
      this.getContributions({ agreement__external_id: this.currentAgreement.external_id })
      this.$emit('updateagreement')
    },

    onAgreementLineEdition (data) {
      let updatedItem = Lset(data.item, data.header, data.value)

      const eurBoxPath = 'discount.eur_box'
      const anticipatedPath = 'discount.anticipated'

      // this if - elseif performs the logic to only allow one type of anticipated discount (eut_box vs anticipated)
      if (data.header === eurBoxPath) {
        updatedItem = Lset(data.item, anticipatedPath, null)
      } else if (data.header === anticipatedPath) {
        updatedItem = Lset(data.item, eurBoxPath, null)
      }

      const eurBoxRappelPath = 'discount.eur_box_base_rappel'
      const rappelPath = 'discount.base_rappel'

      // this if - elseif performs the logic to only allow one type of rappel discount (eut_box vs anticipated)
      if (data.header === eurBoxRappelPath) {
        updatedItem = Lset(data.item, rappelPath, null)
      } else if (data.header === rappelPath) {
        updatedItem = Lset(data.item, eurBoxRappelPath, null)
      }

      const promoPath = 'discount.promo'
      const fixPath = 'discount.fix'

      // this if - elseif performs the logic to only allow one type of discount (promo vs fix)
      if (data.header === promoPath) {
        updatedItem = Lset(data.item, fixPath, null)
      } else if (data.header === fixPath) {
        updatedItem = Lset(data.item, promoPath, null)
      }

      // crud save is implemented in the mixin and will also reload after the post
      // TODO this is a lot of hardcoded fields, think in something more generic / automatic
      this.$store
        .dispatch(`${this.storeModule}/putFormItem`, updatedItem)
        .then(x => { // reload this table and contributions
          this.getContributions({ agreement__external_id: this.currentAgreement.external_id })
          this.reloadItemList({ avoidLoader: true })
          this.$emit('updateagreement')
        })
    }
  },

  beforeDestroy () {
    this.dismissNotifications()
  }
}
</script>
