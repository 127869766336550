<template>
  <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems"
             @save="onEndorsementEdition" :customActions="customActions" :parentKey="this.parentKey"
             :parentValue="this.parentValue" :customOptions="customOptions"
             :editableValues=true>

    <template v-slot:form="{close}">
      <EndorsementForm @save="afterCreation()" @close="close"/>
    </template>

  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions, mapState } from 'vuex'
import EndorsementForm from '../forms/EndorsementForm'
import Lget from 'lodash/get'
import Lset from 'lodash/set'

export default {
  name: 'EndorsementTable',
  components: { EndorsementForm, CrudTable },

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'endorsements',
      filterItems: [],
      customOptions: {
        'sortBy': ['endorsement_type__id'],
        'sortDesc': [false]
      },
      headers: [
        {
          text: 'Aportaciones',
          value: 'contributions',
          formatNumber: false,
          align: 'end',
          hide: false,
          multiSelector: false,
          editable: false,
          modelToUse: 'endorsements.validContributions'
        },
        {
          text: 'Tipo',
          value: 'endorsement_type',
          align: 'end',
          hide: false,
          selector: true,
          formatNumber: false,
          labelName: '',
          modelToUse: 'endorsements.validEndorsementTypes',
          returnObject: true
        },
        { text: 'Descripción', value: 'description', formatNumber: false, align: 'end', editable: true, type: 'string' },
        {
          text: 'Importe con IVA',
          value: 'amount',
          formatNumber: true,
          align: 'end',
          hide: false,
          editable: true,
          type: 'float',
          total: 'total_covered'
        },
        { text: 'Renovación tácita', value: 'props.renovation', align: 'end', hide: false, editable: true, isBoolean: true },
        {
          text: 'Fecha de vencimiento',
          value: 'effective_date',
          align: 'end',
          editable: true,
          hide: false,
          formatNumber: false,
          datePicker: true
        },
        {
          text: 'Subida ficheros',
          value: 'endorsement_file',
          formatNumber: false,
          align: 'end',
          hide: false,
          isFile: true
        }
      ],
      customActions: [
        {
          name: 'duplicate',
          text: 'duplicar seleccionado(s)',
          icon: 'fa-copy',
          action: this.duplicateSelected
        }
      ]
    }
  },

  computed: {
    ...mapState('endorsements', ['extra_fields', 'validEndorsementTypes', 'validContributions']),
    ...mapState('agreements', { currentAgreement: 'currentItem' })
  },

  created () {
    this.getValidContributions(this.currentAgreement.external_id)
    this.getValidEndorsementTypes()
  },
  methods: {
    Lget: Lget,
    ...mapActions('endorsements', ['getValidEndorsementTypes', 'getValidContributions', 'reloadItemList']),

    afterCreation () {
      this.reloadItemList()
    },

    onEndorsementEdition (data) {
      // Check if the data passed from the cell is an object with type
      if (data.value.type) {
        let formData = new FormData()

        // The expected post is a form key-value with the file and the external id to update
        formData.append('endorsement_file', data.value)
        formData.append('external_id', data.item.external_id)

        // Post the data from the crud factory method postFormItem
        this.$store
          .dispatch(`${this.storeModule}/postFormItem`, formData
          ).then(response => {
            this.reloadItemList()
          }).catch(error => {
            console.error({ error })
          })
      } else { // If you do not post file must call normal post item from crud factory
        let updatedItem = Lset(data.item, data.header, data.value)

        this.$store
          .dispatch(`${this.storeModule}/postItem`,
            {
              external_id: updatedItem.external_id,
              description: updatedItem.description,
              endorsement_type: Lget(updatedItem, 'endorsement_type.external_id', ''),
              agreement: this.currentAgreement.external_id,
              amount: updatedItem.amount,
              props: {
                ...updatedItem.props
              },
              effective_date: updatedItem.effective_date,
              endorsement_file: undefined, // Endorsement_file is posted separately
              contributions: this.transformObjectsArrayToExternalIdArray(updatedItem.contributions)
            }
          )
          .then(
            () => {
              this.reloadItemList()
            }
          )
      }
    },

    duplicateSelected (data) {
      // reset external_id because if not update item and dont duplicate it
      let itemsToPost = data.items
        .map(item => ({
          ...item,
          agreement: item.agreement.external_id,
          beneficiary: item.creditor ? item.beneficiary.external_id : undefined,
          endorsement_type: Lget(item, 'endorsement_type.external_id', ''),
          id: undefined,
          external_id: undefined
        }))
      confirm('¿Seguro que quieres duplicar los elementos seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/postManyItems`, itemsToPost).catch((error) => {
            console.log(error)
          }).then(
            () => this.reloadItemList()
          )
    },

    transformObjectsArrayToExternalIdArray (objectsArray) {
      /* Function to return the external ids of the objects instead all the object */
      var arrayToReturn = []
      for (var key in objectsArray) {
        arrayToReturn.push(objectsArray[key].external_id)
      }
      return arrayToReturn
    },

    getPDFName (fullPath) {
      let splitPath = fullPath.split('/')
      return splitPath[splitPath.length - 1]
    }
  }
}
</script>

<style scoped>

</style>
