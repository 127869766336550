<template>
  <CrudTable :storeModule="storeModule" :headers="headers" :filterItems="filterItems" :customOptions="customOptions"
             @save="onMerchandisingCreation" :customActions="customActions" :parentKey="this.parentKey" :parentValue="this.parentValue"
             :editableValues="editableValues">

<!--    <template v-slot:form="{close}">-->
<!--      <MerchandisingForm @save="afterCreation()" @close="close"/>-->
<!--    </template>-->

  </CrudTable>
</template>

<script>
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapActions } from 'vuex'
/* import MerchandisingForm from '../forms/MerchandisingForm' */
import Lget from 'lodash/get'

export default {
  name: 'MerchandisingTable',
  components: {
    /* MerchandisingForm, */ CrudTable },

  props: ['parentKey', 'parentValue', 'editableValues'],

  data () {
    return {
      storeModule: 'merchandising',
      filterItems: [],
      customOptions: {
        'sortBy': ['name'],
        'sortDesc': [false]
      },
      headers: [
        { text: 'Nombre', value: 'name', formatNumber: false, align: 'end', hide: false },
        { text: 'Tipo de material', value: 'type', formatNumber: false, align: 'end', hide: false },
        // {text: 'Precio unitario', value: 'external_id', formatNumber: false, align: 'end', hide: false},
        { text: 'Unidades', value: 'units', formatNumber: false, align: 'end', hide: false },
        { text: 'Importe', value: 'amount', formatNumber: false, align: 'end', hide: false }
      ],
      customActions: [
        {
          name: 'duplicate',
          text: 'duplicar seleccionado(s)',
          icon: 'fa-copy',
          action: this.duplicateSelected
        }
      ]
    }
  },

  computed: {
  },

  created () {
  },
  methods: {
    ...mapActions('merchandising', ['reloadItemList']),
    Lget: Lget,

    afterCreation () {
      this.reloadItemList()
    },

    onMerchandisingCreation () {

    },

    duplicateSelected (data) {
      // reset external_id because if not update item and dont duplicate it
      let itemsToPost = data.items
        .map(item => ({
          ...item,
          id: undefined,
          external_id: undefined
        }))
      confirm('¿Seguro que quieres duplicar los elementos seleccionados?') &&
        this.$store
          .dispatch(`${this.storeModule}/postManyItems`, itemsToPost).catch((error) => {
            console.log(error)
          }).then(
            () => this.reloadItemList()
          )
    }
  }
}
</script>

<style scoped>

</style>
