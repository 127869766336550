<template>
  <v-card >
    <v-form
      :lazy-validation="lazy"
      v-model="valid"
      ref="distributorForm"
    >
      <v-card-title>
        <span v-if="!currentItem.creditor" class="headline">Nuevo acreedor</span>
        <span v-else class="headline">Editar acreedor</span>
      </v-card-title>

      <v-container>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="creditorForm.description"
              label="Razón social *"
              :rules="[...commonValidators]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              label="Nombre *"
              v-model="creditorForm.name"
              required
              :rules="[...commonValidators]"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="creditorForm.props['email']"
              label="Email *"
              required
              :rules="[...commonValidators, ...emailValidator]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="creditorForm.props['phone']"
              label="Teléfono"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              label="CIF *"
              counter="9"
              maxlength="9"
              v-model="creditorForm.props['nif']"
              :rules="[...commonValidators, ...cifValidators]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="creditorForm.props['iban']"
              counter="24"
              maxlength="24"
              label="IBAN *"
              :rules="[...commonValidators, ...ibanValidators]"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="street.name"
              label="Calle *"
              :rules="[...commonValidators]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="street.postal_code"
              label="Código Postal *"
              maxlength="10"
              :rules="[...commonValidators, ...postalCodeValidators]"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="province.name"
              label="Provincia *"
              :rules="[...commonValidators]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              class="pa-2"
              v-model="city.name"
              label="Ciudad *"
              :rules="[...commonValidators]"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-file-input
              v-if="!enabledBtn"
              v-model="file"
              :rules="[...fileValidators]"
              label="Certificado de titularidad bancaria *" />
            <v-text-field
              v-if="enabledBtn && creditorFileName"
              v-model="creditorFileName"
              label="Certificado"
              disabled
              filled>
            </v-text-field>
            <v-btn
              v-if="enabledBtn"
              color="primary"
              @click="enabledAddFileFunction"
              dark
              style="margin-top: -25px"
            >cambiar certificado</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="pb-3 pr-3">
      <v-spacer></v-spacer>
      <v-btn @click="onSave" :loading="btnLoading" :disabled="!valid" color="primary">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEmpty, isNull, isString, isUndefined } from 'lodash'

export default {
  name: 'CreditorForm',
  data () {
    return {
      lazy: false,
      valid: false,
      showAlert: false,
      btnLoading: false,
      creditorFileName: null,
      file: null,
      street: {
        level: 5,
        name: '',
        postal_code: ''
      },
      province: {
        level: 2,
        name: ''
      },
      city: {
        level: 3,
        name: ''
      },
      creditorForm: {
        created_from: 1,
        name: '',
        description: null,
        level: -4,
        active: true,
        props: {},
        editable: true
      },
      creditor: {
        created_from: 1,
        name: '',
        description: null,
        level: -4,
        active: true,
        props: {},
        editable: true
      },
      enabledBtn: false,
      enabledAddFile: false,
      commonValidators: [
        v => !isEmpty(v) || 'El campo no puede estar vacío'
      ],
      fileValidators: [
        v => v !== null || 'El campo no puede estar vacío'
      ],
      emailValidator: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El email no es correcto'
      ],
      cifValidators: [
        v => (this.isValidString(v) && v.length === 9) || 'La longitud permitida es de 9 caracteres'
      ],
      ibanValidators: [
        v => (this.isValidString(v) && v.length === 24) || 'La longitud permitida es de 24 caracteres'
      ],
      postalCodeValidators: [
        v => (this.isValidString(v) && !v.match(/[^0-9]/g)) || 'Sólo se permiten campos numéricos'
      ]
    }
  },
  mounted () {
    if (this.currentItem.creditor) {
      this.checkDistributorFile()
      this.addCreditorToForm()
    } else {
      this.enabledBtn = false
      this.enabledAddFile = false
    }
  },
  destroyed () {
    this.clearForm()
  },
  computed: {
    ...mapState('distributors', {
      locations: 'locations',
      validGroupItems: 'validGroupItems',
      currentDistributor: 'currentItem'
    }),
    ...mapState('agreements', {
      currentItem: 'currentItem'
    })
  },
  methods: {
    ...mapActions('distributors', {
      getCreditor: 'getCreditor',
      postCreditor: 'postCreditor',
      postCreditorFormItem: 'postCreditorFormItem'
    }),
    ...mapActions('locations', {
      postLocation: 'postItem'
    }),
    ...mapActions('agreements', {
      postAgreement: 'postItem'
    }),
    /**
     * Function to check a a value is a valid string
     * @param val javascript object to validate
     * */
    isValidString (val) {
      return !isUndefined(val) && isString(val) && val.length > 0
    },
    /**
     * Function used to create the creditor street and postal code
     * */
    async _saveStreet () {
      if (isNull(this.street.postal_code) || isUndefined(this.street.postal_code)) {
        this.street.postal_code = -1
      }
      return (await this.postLocation({
        ...this.street,
        postal_code: `${this.street.postal_code}`
      })).data.identification_ids[0]
    },
    /**
     * Function used to create the creditor province
     * */
    async _saveProvince () {
      return (await this.postLocation(this.province)).data.identification_ids[0]
    },
    /**
     * Function used to create the creditor city
     * */
    async _saveCity () {
      return (await this.postLocation(this.city)).data.identification_ids[0]
    },
    /**
     * Function used to return created locations
     * */
    async _saveLocations () {
      return {
        street_external_id: await this._saveStreet(),
        province_external_id: await this._saveProvince(),
        city_external_id: await this._saveCity()
      }
    },
    /**
     * Function called when user wants to create
     * a creditor.
     * */
    async onSave () {
      this.btnLoading = true
      if ((!isNull(this.file) && this.$refs.distributorForm.validate()) || this.currentItem.creditor) {
        try {
          // saving Locations
          const pLocationsResponse = await this._saveLocations()
          console.log('post street response')
          console.log(pLocationsResponse)
          // saving creditor

          const pCreditorResponse = await this.postCreditor({
            ...this.creditorForm,
            city: pLocationsResponse.city_external_id,
            province: pLocationsResponse.province_external_id,
            street: pLocationsResponse.street_external_id
          })
          console.log('post creditor response')
          console.log(pCreditorResponse)
          this.creditor = await this.getCreditor(pCreditorResponse.data.identification_ids[0])
          // creating form to send file
          if ((!isNull(this.file) && isNull(this.currentItem.creditor)) || (!isNull(this.file) && this.file !== this.currentItem.creditor.distributor_file)) {
            const formData = new FormData()
            formData.append('distributor_file', this.file)
            formData.append('external_id', this.creditor['external_id'])
            // saving creditor file
            const pCreditorFileResponse = await this.postCreditorFormItem(formData)
            console.log('post creditor file response')
            console.log(pCreditorFileResponse)
            // Reloading Creditor
            this.creditor = await this.getCreditor(this.creditor['external_id'])
          }
          this.$emit('onCreditorCreated', this.creditor)
          this.enabledAddFileFunction()
          this.enabledAddFile = false
          this.btnLoading = false
        } catch (e) {
          this.btnLoading = false
          this.$emit('onError')
        }
      } else {
        this.btnLoading = false
      }
    },
    enabledAddFileFunction () {
      this.enabledBtn = !this.enabledBtn
      this.enabledAddFile = !this.enabledAddFile
    },
    checkDistributorFile () {
      if (this.currentItem.creditor.distributor_file) {
        this.enabledBtn = true
        this.enabledAddFile = false
        let file = this.currentItem.creditor.distributor_file.toString()
        this.creditorFileName = file.slice(file.lastIndexOf('/')).replace(/%20/g, '_')
      } else {
        this.enabledBtn = false
        this.enabledAddFile = true
      }
    },
    addCreditorToForm () {
      this.creditorForm.id = this.currentItem.creditor.id
      this.creditorForm.external_id = this.currentItem.creditor.external_id
      this.creditorForm.name = this.currentItem.creditor.name
      this.creditorForm.description = this.currentItem.creditor.description
      this.creditorForm.level = this.currentItem.creditor.level
      this.creditorForm.props = this.currentItem.creditor.props
      this.city = this.currentItem.creditor.city
      this.province = this.currentItem.creditor.province
      this.street = this.currentItem.creditor.street
    },
    clearForm () {
      this.creditorForm = {
        created_from: 1,
        name: '',
        description: null,
        level: -4,
        active: true,
        props: {},
        editable: true
      }
    }
  }
}
</script>

<style scoped>

</style>
